import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import { useSelector } from "react-redux";
import OrderItem from "../../components/molecules/OrderItem/OrderItem";
import { useEffect, useState } from "react";
import { UserData } from "../../store/slices/UserSlice";
// import loadingimg from "../assets/images/loading-circal.png";
import loadingimg from "../../assets/images/loading-circal.png";
import searchIcon from "../../assets/images/search.svg";
import { OrdersData, OrdersLoading } from "store/slices/OrderSlice";

const Orders = (): JSX.Element => {
  const orders = useSelector(OrdersData);
  const isLoading = useSelector(OrdersLoading);
  const user = useSelector(UserData);
  const [query, setQuery] = useState("");

  const handleSearchOrder = () => {
    // store.dispatch(fetchOrdersAsync({customers: user.user.email , search: query}))
  };
  useEffect(() => {}, []);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader/>*/}
      <div id="container">
        <Header />
        <section className="single-post-section">
          <div className="container-xxl">
            <div className="row m-t-5">
              <div className="col-lg-10 bg-white p-5">
                <div className="post-content">
                  <div className="comments-box" id="comments">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            <h5>ID</h5>
                          </th>
                          <th scope="col">
                            <h5>Customer</h5>
                          </th>
                          <th scope="col">
                            <h5>Order</h5>
                          </th>
                          <th scope="col">
                            <h5>Date</h5>
                          </th>
                          <th scope="col">
                            <h5>Pricing</h5>
                          </th>
                          <th scope="col">
                            <h5>Status</h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading == false &&
                          orders !== undefined &&
                          orders.map((item) => (
                            <OrderItem
                              key={"orders: " + item.order_id}
                              orderId={item.order_id}
                              line_item={item.name}
                              price={item.total}
                              date={item.date_created}
                              status={item.status}
                              src={item.src}
                              customer={
                                user === undefined ? "" : user.user.username
                              }
                            />
                          ))}
                      </tbody>
                    </table>
                    {isLoading == true && (
                      <div
                        className={
                          "d-flex justify-content-center align-items-center loading-center"
                        }
                      >
                        <img src={loadingimg} className="imageloop" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 p-5">
                <div className="sidebar">
                  <div className="sidebar-widget widget_categories">
                    <h4> Orders </h4>
                    <p> Total items: {orders.length} </p>
                    <div className={"d-flex align-items-center"}>
                      <img className="small-icon px-1" src={searchIcon} />
                      <input
                        type={"text"}
                        placeholder={"search"}
                        className={"smallsearch"}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") handleSearchOrder();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Orders;
