import React from "react";
import { useSelector } from "react-redux";
import ProductCard from "../molecules/ProductCard";
import Loading from "../../assets/images/gif/product_loading.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import loadingimg from "../../assets/images/loading-circal.png";
import { ProductsData, ProductsStatus } from "store/slices/ProductsSlice";

const RelatedProduct = (): JSX.Element => {
  const products = useSelector(ProductsData);
  const isLoading = useSelector(ProductsStatus);

  return (
    <section className="portfolio-section">
      <div className="container-xl">
        <h2 className="pb-3">Related Products</h2>
        {/*<div className="portfolio-box iso-call h-auto">*/}
        {/*    {*/}
        {/*        isLoading ? (*/}
        {/*            [...Array(3)].map((e, i) => {*/}
        {/*                    return <ProductCard id={-1} key={"loadingProduct_" + i}  name={""} short_description={""} images={[{*/}
        {/*                        name: "loading image...",*/}
        {/*                        src: Loading,*/}
        {/*                        alt: "loading product image"*/}
        {/*                    }]}/>*/}
        {/*                }*/}
        {/*            )*/}
        {/*        ):(*/}
        {/*            products.map((item,index) => {*/}
        {/*            if (index < 4)*/}
        {/*                return <ProductCard key={"sixProduct_" + item.id} id={item.id} name={item.name}*/}
        {/*                                short_description={item.short_description} images={item.images}/>*/}
        {/*        }))*/}
        {/*    }*/}
        {/*</div>*/}
        <Swiper
          className={"h-75"}
          breakpoints={{
            // when window width is >= 640px
            420: {
              width: 420,
              slidesPerView: 1,
              spaceBetween: 50,
            },
            640: {
              width: 640,
              slidesPerView: 1,
              spaceBetween: 50,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            980: {
              width: 980,
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {isLoading ? (
            <div
              className={
                "d-flex justify-content-center align-items-center loading-center"
              }
            >
              <img src={loadingimg} className="imageloop" />
            </div>
          ) : (
            products.map((item, index) => {
              if (index < 4)
                return (
                  <SwiperSlide>
                    <ProductCard
                      key={"sixProduct_" + item.id}
                      id={item.id}
                      name={item.name}
                      short_description={item.short_description}
                      images={item.images}
                    />
                  </SwiperSlide>
                );
            })
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default RelatedProduct;
