import React, { useEffect, useState } from "react";
import { Categories, Category } from "../../../models/Category";
import { Link } from "react-router-dom";
import store from "../../../store";
import { fetchProductsAsync } from "../../../store/Thunks/ProductThunks";
import star from "../../../assets/icons/Star 1.svg";
import starempty from "../../../assets/icons/Star-empty.svg";
import Product from "screens/Products";

interface params {
  name?: string;
  categories?: Array<Category>;
  description?: string;
  short_description?: string;
  price?: number;
  sale_price?: number;
  onByNow?: any;
  onAddToCart?: any;
  onDownload?: any;
  rank?: number;
}

const ProductDetails: React.FC<params> = ({
  name,
  price,
  categories,
  short_description,
  sale_price,
  description,
  onByNow,
  onAddToCart,
  onDownload,
  rank,
}): JSX.Element => {
  const [style, setStyle] = useState("");
  const [isPromo, setIsPromo] = useState(false);
  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    handlePrice();
  }, []);

  const handlePrice = () => {
    if (sale_price != undefined && price != undefined && sale_price < price) {
      setStyle(" me-4 text-decoration-line-through");
      // console.log("you enter to the handle price first condition ");
      setIsPromo(true);
    }

    if (sale_price != undefined && sale_price == 0) {
      setIsFree(true);
      console.log(
        "the product is free: " +
          JSON.stringify([
            name,
            price,
            sale_price,
            categories,
            short_description,
            rank,
          ])
      );
      setStyle("none");
    }
  };
  return (
    <div className="project-content">
      <h2>
        {name}
        {"       \t\t"}
      </h2>
      <ul className="project-data-list">
        <li>
          <div className="py-3">
            {[...Array(5)].map((e, i) => (
              <button className="button-icon">
                <img src={rank != undefined && i < rank ? star : starempty} />
              </button>
            ))}
          </div>
        </li>
        <li>
          <span className="data-name">price</span>
          <span className="data-text h2">
            <b className={`h5 ${style}`}>{isFree ? "" : price + "$"} </b>
            {isPromo == true ? (
              <b className="h5">{isFree ? "Free" : sale_price + "$"}</b>
            ) : (
              <></>
            )}
          </span>
        </li>

        <li>
          <span className="data-name">Category</span>
          <span className="data-text">
            {categories &&
              categories.map((item) => (
                <Link
                  to={"/search"}
                  className={"hyperlink"}
                  onClick={() =>
                    store.dispatch(
                      fetchProductsAsync({ category: item.category_id })
                    )
                  }
                >
                  {item.name},&nbsp;&nbsp;
                </Link>
              ))}
          </span>
        </li>

        <li>
          <span className="data-name">Description</span>
          <span className="data-text">
            {short_description &&
              short_description.replace(/(<([^>]+)>)/gi, "")}
          </span>
        </li>
      </ul>
      <ul className="project-data-list mt-5">
        {isFree != true ? (
          <>
            <Link to={"#"} className="theme-button" onClick={onAddToCart}>
              <span>Add To Cart</span>
            </Link>
            <Link to={"#"} className="theme-button mx-2" onClick={onByNow}>
              <span>Buy Now</span>
            </Link>
          </>
        ) : (
          <>
            <Link to={"#"} className="theme-button mx-2" onClick={onDownload}>
              <span>Download Now</span>
            </Link>
          </>
        )}
      </ul>
    </div>
  );
};

export default ProductDetails;
