import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {Order, OrderN, OrderNode, OrderType} from "../../models/Order";
import {deleteOrder, fetchOrderThunk, postOrder, putOrder} from "../Thunks/OrderThunks";
import {intialOrderState} from "../intialState";



export const OrdersSlice = createSlice({
    name: "order",
    initialState: intialOrderState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postOrder.pending, (state: OrderN) => {
                state.isLoading = true
            })
            .addCase(postOrder.fulfilled, (state: OrderN, action: PayloadAction<any>) => {
                state.isLoading = false
                state.data = action.payload
                state.error = ""
            })
            .addCase(postOrder.rejected, (state: OrderN, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(putOrder.pending, (state: OrderN) => {
                state.isLoading = true
            })
            .addCase(putOrder.fulfilled, (state: OrderN, action: PayloadAction<OrderType>) => {
                state.isLoading = false
                state.data = action.payload
                state.error = ""
            })
            .addCase(putOrder.rejected, (state: OrderN, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            
    }
})

export const OrderData = (state: RootState): any =>
    state.order.data
export const OrderError = (state: RootState): string =>
    state.order.error
export const OrderLoading = (state: RootState): boolean =>
    state.order.isLoading

export default OrdersSlice.reducer