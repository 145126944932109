import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "store"
import { addReviewThunk, fetchReviewThunk, removeReviewThunk } from "store/Thunks/ReviewThunks"

// "review_id": 1,
// "user_id": 1,
// "product_id": 7,
// "date_created": "2024-05-01T23:00:00.000Z",
// "message": "this product is so good",
// "rank": 4

export type ReviewType = {
    review_id? : number,
    user_id? : number,
    username?: string,
    product_id? : number,
    data_created? : string,
    message? : string,
    rank?: number
}

interface ReviewNode {
    data: Array<ReviewType>,
    error: string,
    isLoading: boolean,
}

const initialReviewstate:ReviewNode = {
    data : [],
    error: "",
    isLoading: true,
}

export type removeReviewType = {
    user_id : number,
    review_id : number
}

const ReviewSlice = createSlice({
    name: "review",
    initialState: initialReviewstate,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReviewThunk.pending, (state: ReviewNode) => {
                state.isLoading = true
            })
            .addCase(fetchReviewThunk.fulfilled, (state: ReviewNode, action: PayloadAction<Array<ReviewType>>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchReviewThunk.rejected, (state: ReviewNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(addReviewThunk.pending, (state: ReviewNode) => {
                state.isLoading = true
            })
            .addCase(addReviewThunk.fulfilled, (state: ReviewNode, action: PayloadAction<ReviewType>) => {
                state.isLoading = false
                state.data.push(action.payload)
            })
            .addCase(addReviewThunk.rejected, (state: ReviewNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(removeReviewThunk.pending, (state: ReviewNode) => {
                state.isLoading = true
            })
            .addCase(removeReviewThunk.fulfilled, (state: ReviewNode, action: PayloadAction<ReviewType>) => {
                state.isLoading = false
                state.data = state.data.filter(item => item.review_id != action.payload.review_id && item.user_id != action.payload.user_id)
            })
            .addCase(removeReviewThunk.rejected, (state: ReviewNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })

    }
})

export const ReviewStatus = (state: RootState): boolean =>
    state.review.isLoading

export const ReviewData = (state: RootState): Array<ReviewType> =>
    state.review.data

export const ReviewError = (state: RootState): string =>
    state.review.error

export default ReviewSlice.reducer
