import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {intialCartState} from "../intialState";
import {RootState} from "../index";
import {CartItem, CartNode} from "../../models/Cart";
import { fetchCartThunk, AddProductToCartThunk, DeleteItemFromCartThunk } from "store/Thunks/CartThunks";
import { resetAll } from "store/Thunks/resetSlice";

const CartSlice = createSlice({
    name: "cart",
    initialState: intialCartState,
    reducers: {},
    extraReducers: (builder) => {
    builder
        .addCase(resetAll, () => intialCartState)
        .addCase(fetchCartThunk.pending, (state: CartNode) => {
            state.isLoading = true
            state.access = false
        })
        .addCase(fetchCartThunk.fulfilled, (state: CartNode, action: PayloadAction<Array<CartItem>>) => {
            state.isLoading = false
            state.access = true
            state.data = action.payload
            state.count = action.payload?.length
            console.log("the totale price is :"+ JSON.stringify(state.data))
            state.totalePrice = state.data.reduce((e, i) => e + i.sale_price , 0)
        })
        .addCase(fetchCartThunk.rejected, (state: CartNode, action: PayloadAction<any>) => {
            state.isLoading = false
            state.access = false
            console.log("you enter to error mode in cart: "+ action.payload.status)
            state.error = action.payload.message
        })
        .addCase(AddProductToCartThunk.pending, (state: CartNode) => {
            state.isLoading = true
        })
        .addCase(AddProductToCartThunk.fulfilled, (state: CartNode, action: PayloadAction<CartItem>) => {
            state.isLoading = false
            state.data.push(action.payload)
            state.count = state.data.length
            state.totalePrice = state.data.reduce((e, i) => e + i.sale_price , 0)
        })
        .addCase(AddProductToCartThunk.rejected, (state: CartNode) => {
            state.isLoading = false
        })
        .addCase(DeleteItemFromCartThunk.pending, (state: CartNode) => {
            state.isLoading = true
        })
        .addCase(DeleteItemFromCartThunk.fulfilled, (state: CartNode, action: PayloadAction<number>) => {
            state.isLoading = false
            state.data = state.data.filter(item => item.cart_id !== action.payload)
            state.count--;

            state.data?.map((item) => {
                state.totalePrice = +state.totalePrice + +(state.totalePrice !== undefined ? item.sale_price : 0)
           })
        })
},
})

export const CartAccess = (state: RootState): boolean =>
    state.cart.access

export const CartStatus = (state: RootState): boolean =>
    state.cart.isLoading

export const CartData = (state: RootState): CartNode =>
{
    return state.cart
}


export const CartError = (state: RootState): any =>
    state.cart.error

// export const CartTotalPrice = (state: RootState): number =>
//     state.cart.totalPrice

// export const CartTotalItems = (state: RootState): number =>
//     state.cart.totalItems

// export const { InitCart, AddToCart, RemoveFromCart } =
//     CartSlice.actions

// export const {RemoveFromCart} = CartSlice.actions
export default CartSlice.reducer