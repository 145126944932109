import { createAsyncThunk } from "@reduxjs/toolkit"
import { ServerError } from "models/error"
import { axiosNew } from "services/api"
import { ReviewType, removeReviewType } from "store/slices/ReviewSlice"


export const fetchReviewThunk = createAsyncThunk<
any,
number,
{
    rejectValue:ServerError
}
>(
    'review/ReviewProduct',
    async (id,{rejectWithValue}): Promise<any> => {
        try {
            const {data}  = await axiosNew.get(`/review/${id}`)
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

export const addReviewThunk = createAsyncThunk<
any,
ReviewType,
{
    rejectValue:ServerError
}
>(
    'review/AddReviewProduct',
    async (params,{rejectWithValue}): Promise<any> => {
        try {
            const {data}  = await axiosNew.post(`/review`, {
                data: params
            })
            return params
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)



export const removeReviewThunk = createAsyncThunk<
any,
ReviewType,
{
    rejectValue:ServerError
}
>(
    'review/removeReview',
    async (review, {rejectWithValue}): Promise<any> => {
        try {

            console.log("the data from remove thunk : "+ JSON.stringify(review))
            const {data}  = await axiosNew.delete(`/review`, {
                data: review
            })
            return review
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)