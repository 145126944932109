import React from "react";

interface Field {
  id: string;
  name: string;
  value?: string;
  type?: string;
  onChange?: any;
  label?: string;
  placeholder?: string;
  className?: string;
  isReuired?: boolean;
  pattern?: string;
  title?: string;
  maxlength?: number;
}

export const TextField: React.FC<Field> = ({
  id,
  name,
  value,
  type,
  onChange,
  placeholder,
  pattern,
  label,
  className,
  isReuired,
  title,
  maxlength,
}): JSX.Element => {
  const handlerOnChange = (e: string) => {
    onChange(e);
  };
  return (
    <div className="input-line">
      <label htmlFor={name} className={"pb-2"}>
        {className !== undefined ? (
          label !== undefined ? (
            label
          ) : (
            placeholder
          )
        ) : (
          <i className="fas fa-envelope"></i>
        )}
      </label>
      <input
        name={name}
        id={id}
        type={type}
        className={className !== undefined ? className : ""}
        pattern={pattern}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handlerOnChange(e.target.value)}
        title={title != undefined ? title : ""}
        maxLength={maxlength}
        required={isReuired === undefined ? true : false}
      />
    </div>
  );
};

interface FieldArea {
  id: string;
  name: string;
  row?: number;
  value?: string;
  type?: string;
  onChange?: any;
  placeholder: string;
  className?: string;
}

export const TextArea: React.FC<FieldArea> = ({
  id,
  name,
  value,
  type,
  row,
  onChange,
  placeholder,
  className,
}): JSX.Element => {
  const handlerOnChange = (e: string) => {
    onChange(e);
  };
  return (
    <div className="input-line">
      <label htmlFor={name} className={"pb-2"}>
        {className !== undefined ? (
          placeholder
        ) : (
          <i className="fas fa-envelope"></i>
        )}
      </label>
      <textarea
        name={name}
        id={id}
        placeholder={placeholder}
        rows={row}
        className={className !== undefined ? className : ""}
        value={value}
        onChange={(e) => handlerOnChange(e.target.value)}
      ></textarea>
    </div>
  );
};
