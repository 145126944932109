import React, { ChangeEvent, useEffect, useState } from "react";
import store from "store";
import { addImageThunk } from "store/Thunks/ImageThunks";
import { useSelector } from "react-redux";
import {
  ImageUploadProgress,
  ImagesData,
  ImagesStatus,
  setUploadProgress,
} from "store/slices/ImageSlice";
import ShowFiles from "./ShowFiles";

import {
  ArchiveData,
  ArchiveStatus,
  ArchiveUploadProgress,
} from "store/slices/ArchiveSlice";
import { addArchiveThunk } from "store/Thunks/ArchiveThunks";

type params = {
  type: number;
  title: string;
  value?: any;
};

type File = {
  fileName: string;
  fileContents: string;
};

type Image = {
  id: number;
  src: string;
};

type FileSrc = {
  src: any;
  url: any;
};
const MultiFileUpload: React.FC<params> = ({
  type,
  title,
  value,
}): JSX.Element => {
  const [file, setFile] = useState<string | undefined>(undefined);
  const [filebase64, setFileBase64] = useState<File | undefined>(undefined);
  // const [filesrc, setFilesrc] = useState<Array<File>>([]);
  const [filesrc, setFilesrc] = useState<Array<FileSrc>>([]);
  const [onefilesrc, setOnefilesrc] = useState<FileSrc>();
  const [paths, setPaths] = useState<Array<string>>([]);
  const [src, setSrc] = useState<string>("");
  const imageIsLoading = useSelector(ImagesStatus);
  const imageProgress = useSelector(ImageUploadProgress);
  const archiveProgress = useSelector(ArchiveUploadProgress);
  const imageData = useSelector(ImagesData);
  const archiveData = useSelector(ArchiveData);
  const [selectedFiles, setSelectedFiles] = useState<any | undefined>(
    undefined
  );
  // const [selectedImage, setSelectedImage] = useState<Array<string>>([]);
  // const [progress, setProgress] = useState(0);
  const [images, setImages] = useState<Array<Image>>([]);

  const handleFileChange = async (filesx: FileList | null) => {
    if (filesx) {
      store.dispatch(setUploadProgress(0));
      const fileRef = filesx[0] || undefined;
      if (!filesrc.includes(selectedFiles)) {
        await readFileAsDataURL(fileRef).then((e) => {
          const file: FileSrc = {
            src: fileRef,
            url: e,
          };
          setOnefilesrc(file);
          // setFilesrc([...filesrc, file]);
        });
      }
    }
  };

  // function convertFile(filesx: FileList | null) {
  //   store.dispatch(setUploadProgress(0));
  //   if (filesx) {
  //     const fileRef = filesx[0] || "";
  //     const fileType: string = fileRef.type || "";

  //     const reader = new FileReader();
  //     reader.readAsBinaryString(fileRef);
  //     // if (type == 1) {
  //     reader.onload = (ev: any) => {
  //       // convert it to base64
  //       setFileBase64({
  //         fileName: fileRef.name,
  //         fileContents: `${btoa(ev.target.result)}`,
  //         //   fileContents: `data:${fileType};base64,${btoa(ev.target.result)}`,
  //       });
  //     };
  //   }
  // }

  const deleteFileSrc = (src?: any) => {
    store.dispatch(setUploadProgress(0));
    if (type == 1) {
      setFilesrc(filesrc.filter((e, i) => i != src));
    } else if (type == 2 || type == 3) {
      setFilesrc([]);
      setFile(undefined);
    }
  };

  const handleUpload = async () => {
    if (type == 1 || type == 3) {
      const formData = new FormData();
      if (filesrc.length > 0) {
        Array.from(filesrc).forEach((file) => {
          console.log("file name is :" + file.src?.name);
          formData.append("files", file.src);
        });
        store.dispatch(addImageThunk(formData));
      }
    } else if (type == 2) {
      if (filesrc != undefined && filesrc.length > 0) {
        const formData = new FormData();
        formData.append("file", filesrc[0].src);
        store.dispatch(addArchiveThunk(formData));
      }
    }
  };

  const readFileAsDataURL = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (filesrc.length == 0) {
      if (onefilesrc != undefined) {
        // let value:File = {fileName: }

        setFilesrc([onefilesrc]);
      }
    } else if (
      onefilesrc != undefined &&
      !filesrc.includes(onefilesrc) &&
      type == 1
    ) {
      //   if (type == 3) setFilesrc([filebase64])
      setFilesrc([...filesrc, onefilesrc]);
    }
    if (paths.length == 0) {
      if (src != "") setPaths([src]);
    } else if (!paths.includes(src)) setPaths([...paths, src]);
    if (
      imageIsLoading !== undefined &&
      imageIsLoading === false &&
      paths.length > 0
    ) {
      // files(paths);
    }
  }, [onefilesrc, src, paths]);

  useEffect(() => {
    if (value != undefined) {
      setImages(value);
      console.log(
        "the value from upload multifiles: " + JSON.stringify(images)
      );
    }
    // }
  }, [images]);

  return (
    <form>
      <div className="d-flex flex-column align-items-center justify-content-start">
        <div className={""}>
          <label
            htmlFor={"image-uploadify" + type}
            className="custom-file-label form-label"
          >
            {title}
          </label>
          <br />
          <input
            id={"image-uploadify" + type}
            type="file"
            key={"input-file-upload_" + type}
            // onChange={(e) => convertFile(e.target.files)}
            onChange={(e) => handleFileChange(e.target.files)}
            accept=".xlsx,.xls,image/*,.doc,audio/*,.docx,video/*,.ppt,.pptx,.txt,.pdf,.zip"
            multiple
            className={"custom-file-upload"}
          />
        </div>
        <ShowFiles filesrc={filesrc} onDelete={deleteFileSrc} type={type} />
        {filesrc.length > 0 && (
          <div className={"d-flex flex-column"}>
            <a
              onClick={handleUpload}
              type={"submit"}
              className={"hyperlink"}
              id="submit_contact"
            >
              upload
            </a>
            <div className={""}>
              {imageIsLoading !== undefined &&
                imageIsLoading === false &&
                paths.length > 0 &&
                paths.map((e) => (
                  <div className={"mb-3 "}>
                    <a href={e} className={"hyperlink"} target="_blank">
                      {e}
                    </a>
                    <br />
                  </div>
                ))}
            </div>
          </div>
        )}
        <div>
          {((imageProgress != 0 && (type == 1 || type == 3)) ||
            (archiveProgress != 0 && type == 2)) &&
          filesrc != undefined ? (
            filesrc.map((item: any) => (
              <div key={item.name + Math.random() * 100} className="mt-5">
                <p className="text-center">
                  {item.src != undefined ? item.src.name : ""}
                </p>
                <div className="progress-container-1 mt-3 ">
                  <div
                    className="progress-item-1"
                    style={{
                      width: `${
                        type == 1 || type == 3 ? imageProgress : archiveProgress
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
};

export default MultiFileUpload;
