import { TextArea, TextField } from "../atoms/TextField";
import { Button } from "../atoms/Button";
import { InfoBox } from "../atoms/InfoBox";
import { useState } from "react";
import axios from "axios";
import store from "store";
import { sendMessageThunk } from "store/Thunks/ContactThunk";
import { axiosNew } from "services/api";

const ContactFooter = (): JSX.Element => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const handleName = (e: string) => {
    setName(e);
  };

  const handleMail = (e: string) => {
    setMail(e);
  };

  const handleSubject = (e: string) => {
    setSubject(e);
  };

  const handleMessage = (e: string) => {
    setMessage(e);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name == "" || mail == "" || message == "" || subject == "")
        return;
    try {
      const { data } = await axiosNew.post(`/contact`, {
        data: {
          name: name,
          email: mail,
          message: message,
          subject: subject,
        },
      });
      setInfo(data.message);
      return data;
    } catch (error: any) {
      if (!error) setError(error);
    }
  };

  return (
    <section className="contact-info-section">
      <div className="container">
        <div className="title-box">
          <span>Get in touch</span>
          <h2>Contact us</h2>
        </div>
        <div className="contact-info-box">
          <div className="row g-0">
            <InfoBox
              title={"Reception Desk"}
              description={"(+01) 123 456 7899"}
              colClass={"col-lg-4"}
              iconClass={"fas fa-phone"}
            />
            <InfoBox
              title={"Working Hours"}
              description={"Monday - Friday / 08:00-17:00"}
              colClass={"col-lg-4"}
              iconClass={"fas fa-clock"}
            />
            <InfoBox
              title={"Address"}
              description={"Example street nr 23"}
              colClass={"col-lg-4"}
              iconClass={"fas fa-map-marker-alt"}
            />
          </div>
        </div>
        <div className="contact-form-box">
          {info != "" ? (
            <div className="cover-success p-2 d-flex justify-content-center align-item-center">
              <h4>{info}</h4>
            </div>
          ) : (
            <></>
          )}
          <div className="row g-0">
            <div className="col-lg-8">
              <form id="contact-form" onSubmit={handleSubmit}>
                <h4>Send message</h4>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      id={"name"}
                      type={"text"}
                      name={"name"}
                      placeholder={"Full Name"}
                      onChange={handleName}
                    />
                    <TextField
                      id={"mail"}
                      type={"text"}
                      name={"mail"}
                      placeholder={"E-mail"}
                      onChange={handleMail}
                    />
                    <TextField
                      id={"subject"}
                      type={"text"}
                      name={"subject"}
                      placeholder={"Subject"}
                      onChange={handleSubject}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextArea
                      id={"comment"}
                      name={"comment"}
                      placeholder={"Message"}
                      onChange={handleMessage}
                    />
                    <Button
                      value={"Send message"}
                      type={"submit"}
                      id={"submit_contact"}
                    />
                  </div>
                </div>
                <div id="msg"></div>
              </form>
            </div>
            <div className="col-lg-4">
              <div id="map"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFooter;
