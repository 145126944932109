import { useEffect } from "react";
import Header from "../../components/sections/Header";
import ImagesProduct from "./Components/ImagesProduct";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/sections/Footer";
import {
  fetchProductByIdAsync,
  fetchProductsAsync,
} from "../../store/Thunks/ProductThunks";
import RelatedProduct from "../../components/sections/RelatedProduct";
import ProductDetails from "./Components/ProductDetails";
import {
  ProductIdData,
  ProductIdStatus,
} from "../../store/slices/productIdSlice";
import store from "../../store";
import { AddProductToCartThunk } from "../../store/Thunks/CartThunks";
import LoadingPage from "../../assets/images/gif/loading-product.gif";
import { CategoriesData } from "store/slices/CategoriesSlice";
import { UserData, UserLogin } from "store/slices/UserSlice";
import { downloadArchive } from "store/Thunks/DownloadThunks";
import Review from "./Components/Review";
import { addToCheckOut, checkoutNode } from "store/slices/CheckoutSlice";

interface Params {
  id: string;
}

const SingleProduct = ({}): JSX.Element => {
  const history = useHistory();
  const params: Params = useParams();
  const product = useSelector(ProductIdData);
  const isLoading = useSelector(ProductIdStatus);
  const user = useSelector(UserData);
  const dispatch = useDispatch();
  const categories = useSelector(CategoriesData);
  const isLogin = useSelector(UserLogin);

  useEffect(() => {
    if (product.name === "")
      dispatch(fetchProductByIdAsync(parseInt(params.id)));
    // @ts-ignore
    dispatch(fetchProductsAsync(product.related_ids));
  }, [product]);

  const onBuyNowHandler = () => {
    // cart_id: number,
    // product_id: number,
    // name: string,
    // short_description: string,
    // price: number,
    // src: string,
    // alt:string,
    // quantity?: number,
    // user_id: number
    if (isLogin) {
      const obj: checkoutNode = {
        amount: product.sale_price,
        list_items: [
          {
            id: product.id != undefined ? product?.id : 1,
            name: product.name,
            sale_price:
              product.sale_price != undefined ? product?.sale_price : 1,
          },
        ],

        //   name: names.join(" / "),
      };
      store.dispatch(addToCheckOut(obj));
      history.push("/checkout");
    } else {
      history.push("/login");
    }
  };

  const onAddToCartHandler = () => {
    if (isLogin) {
      store.dispatch(
        AddProductToCartThunk({
          product_id: product.id != undefined ? product.id : 1,
          name: product.name,
          short_description: product.short_description,
          sale_price: product.sale_price != undefined ? product.sale_price : 0,
          src: product.images[0].src,
          alt: product.images[0].alt,
          quantity: 1,
          user_id: user.user.id,
        })
      );
      history.push("/cart");
    } else {
      history.push("/login");
    }
  };

  const onDownloadHandler = () => {
    if (isLoading == false && product.id != undefined) {
      store.dispatch(
        downloadArchive({
          product_id: product.id,
          user_id: user.user.id,
        })
      );
    } else history.push("/login");
  };

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <Header />
        <section className="single-project-section">
          <div className="container">
            <div className="single-project-content">
              {isLoading == true ? (
                <div className="row">
                  <img src={LoadingPage} alt={"loading product"} />
                  {/*<ImagesProduct images={[{*/}
                  {/*    name: "loading image...",*/}
                  {/*    src: Loading,*/}
                  {/*    alt: "loading product image"*/}
                  {/*    }]}/>*/}
                  {/*<div className="col-md-6">*/}
                  {/*    <ProductDetails*/}
                  {/*        name={""}*/}
                  {/*        description={""}*/}
                  {/*        tags={[]}*/}
                  {/*        categories={[]}*/}
                  {/*        price={0}*/}
                  {/*    />*/}
                  {/*</div>*/}
                </div>
              ) : (
                <div className="row">
                  <ImagesProduct images={product?.images} />
                  <div className="col-md-6">
                    <ProductDetails
                      rank={product.rank}
                      name={product.name}
                      short_description={product.short_description}
                      categories={categories.filter(
                        (item) => item.category_id == product.categories[0]
                      )}
                      price={product.regular_price}
                      sale_price={product.sale_price}
                      onByNow={onBuyNowHandler}
                      onAddToCart={onAddToCartHandler}
                      onDownload={onDownloadHandler}
                    />
                  </div>
                </div>
              )}
            </div>
            <Review description={product?.description} productId={product.id} />
            <RelatedProduct />
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default SingleProduct;
