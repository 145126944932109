import { NavLink, Link, Redirect } from "react-router-dom";
import logo from "../../assets/images/logo-image.svg";
import React, {
  HtmlHTMLAttributes,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSlice, {
  InitAuth,
  UserData,
  UserError,
  UserLoading,
  UserLogin,
} from "../../store/slices/UserSlice";
import store from "../../store";
import { fetchOrderThunk } from "../../store/Thunks/OrderThunks";
import { TokenModel, User, UserNode } from "../../models/user";
import Helmet from "react-helmet";
// import {InitCart} from "../../store/slices/CartSlice";
import { CartData } from "../../store/slices/CartSlice";
import { FaUser } from "react-icons/all";
import { logOut } from "store/Thunks/AuthThunks";
import { fetchProductsAsync } from "store/Thunks/ProductThunks";
// interface param{
//     login?: TokenModel
// }

const Header = (): JSX.Element => {
  // const [state, dispatch] = useReducer(UserSlice, intialAuthState)

  let isLoading: boolean = useSelector(UserLoading);
  let isLogin: boolean = useSelector(UserLogin);
  let error: string = useSelector(UserError);
  let user: User = useSelector(UserData);
  let cartData = useSelector(CartData);

  const [isActive, setActive] = useState(false);
  const [isActiveAccount, setActiveAccount] = useState(false);
  const [isActiveOption, setActiveOption] = useState(false);
  // const [subMenuActive, setSubMenuActive] = useState(false);

  const handleClick = (event: any) => {
    event.preventDefault();
    // 👇️ toggle isActive state on click
    setActive((current) => !current);
  };

  const handleOptionClick = (event: any) => {
    event.preventDefault();
    setActiveOption((current) => !current);
  };

  const handlerAccountClick = (event: any) => {
    event.preventDefault();
    setActiveAccount((current) => !current);
  };

  const printConsole = () => {
    console.log("you click Account button");
  };

  const onLogOutHandler = () => {
    store.dispatch(logOut());
  };

  useEffect(()=>{
    console.log("hello world!");
  })
  return (
    <header className="clearfix">
      <div className={`logo ${isActive ? "active" : ""}`}>
        <Link
          className="aa"
          to={"/"}
          onClick={() => {
            store.dispatch(fetchProductsAsync());
            // i write the
            // dispatch(InitAuth())
            // store.dispatch(fetchRecentProductsAsync())
            // store.dispatch(fetchCartThunk())
            // store.dispatch(fetchCategoriesAsync())
            // store.dispatch(fetchTagsAsync())
            // store.dispatch(fetchPostsAsync({per_page: 2, page:1}))
            // store.dispatch(InitCart())
          }}
        >
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="header-line">
        {/*<button }>click me</button>*/}
        <a
          className={`open-menu-toggle aa ${isActive ? "active" : ""}`}
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
        <div className={`social-line ${isActive ? "active" : ""}`}>
          <ul className="social-list">
            <li>
              <div className={"cart-container"}>{cartData.count}</div>
              <Link to={cartData.count > 0 ? "/cart" : ""}>
                <i className="fas fa-shopping-cart"></i>
              </Link>
            </li>
            <li>
              <Link to={isLogin == true ? "/account" : "/login"}>
                <FaUser className={"pb-1"} size={20} />
              </Link>
            </li>
          </ul>
        </div>
        <div className="copyright-line">
          <a href={"https://myheart.ma"}>
            <p>© MyMeart 2022</p>
          </a>
        </div>
      </div>
      a
      <nav className={`nav-menu-box  ${isActive ? "active" : ""}`}>
        <ul className="navigation-menu-list">
          <li className="menu-item-has-children">
            <NavLink
              to={"/"}
              className="aa"
              onClick={() => {
                store.dispatch(fetchProductsAsync());
                // code for
              }}
            >
              <span>
                <em>Home</em>
              </span>
            </NavLink>
          </li>
          <li>
            <Link to={"/collection"} className="aa">
              <span>
                <em>Collection</em>
              </span>
            </Link>
          </li>
          <li className="menu-item-has-children">
            {/* data-bs-toggle="collapse" data-bs-target="#demo" */}
            <NavLink
              to={""}
              className={`aa ${isActiveAccount ? " opened" : ""}`}
              // onClick={() => setActiveAccount((current) => !current)}
              onClick={handlerAccountClick}
            >
              <span>
                <em>Account</em>
              </span>
            </NavLink>
            {isLogin == false ? (
              <ul
                className={`sub-menu  ${isActiveAccount ? "show" : ""}`}
                id={"demo"}
              >
                <li>
                  <Link to={"/login"}>Login</Link>
                </li>
                <li>
                  <Link to={"/signup"}>Sign Up</Link>
                </li>
              </ul>
            ) : (
              <ul
                className={`sub-menu  ${isActiveAccount ? "show" : ""}`}
                id={"demo"}
              >
                <li>
                  <Link className="aa" to={"/orders"}>
                    {" "}
                    Orders{" "}
                  </Link>
                </li>
                {/*{(customer.role === "admin") && <li><Link to={"/product"}>Add new Product</Link></li>}*/}
                <li>
                  <Link to={"/downloads"}>Downloads</Link>
                </li>
                {/*<li><a href={"/addresses"}>Addresses</a></li>*/}
                <li>
                  <Link to={"/account"}>Account Details</Link>
                </li>
                {/*<li><a href={"/wishlist"}>Wishlist</a></li>*/}
                <li>
                  <Link to={"/login"} onClick={onLogOutHandler}>
                    Logout
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {user.user.is_admin == 1 ? (
            <li className="menu-item-has-children">
              {/* data-bs-toggle="collapse" data-bs-target="#demo" */}
              <NavLink
                to={""}
                className={`aa ${isActiveOption ? " opened" : ""}`}
                onClick={handleOptionClick}
              >
                <span>
                  <em>Admin Options</em>
                </span>
              </NavLink>
              <ul
                className={`sub-menu  ${isActiveOption ? "show" : ""}`}
                id={"demo"}
              >
                <li>
                  <NavLink to={"/category"}>Categories</NavLink>
                </li>
                <li>
                  <NavLink to={"/product"}>Products</NavLink>
                </li>
              </ul>
            </li>
          ) : (
            <></>
          )}
          {/* <li>
            <Link to={"/blog"} className="aa">
              <span>
                <em>Blog</em>
              </span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contact"} className="aa">
              <span>
                <em>Contact</em>
              </span>
            </Link>
          </li>
        </ul>
      </nav>
      <Helmet>
        {/* <script src="../../assets/js/script.js"></script> */}
        {/* <script src='../../assets/js/bootstrap.min.js'></script> */}
      </Helmet>
    </header>
  );
};

export default Header;
