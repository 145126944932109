import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "../../components/atoms/TextField";
import loadingimg from "../../assets/images/loading-circal.png";
import { wooApi } from "../../services/api";
import { UserData, UserLoading } from "../../store/slices/UserSlice";
import store from "store";
import { postOrder, putOrder } from "store/Thunks/OrderThunks";
import { ProductIdData } from "store/slices/productIdSlice";
import { checkoutThunk } from "store/Thunks/CheckoutThunk";
import {
  CheckoutData,
  CheckoutError,
  checkoutNode,
  checkoutSuccess,
} from "store/slices/CheckoutSlice";
// stripe lib
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory, useParams } from "react-router-dom";
import Cart from "screens/Cart";
import { CartData } from "store/slices/CartSlice";
import { downloadArchive } from "store/Thunks/DownloadThunks";
import { OrderType } from "models/Order";
import { OrderData, OrderError, OrderLoading } from "store/slices/OrderIdSlice";

const Checkout = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const product = useSelector(ProductIdData);
  const user = useSelector(UserData);
  const checkoutdata = useSelector(CheckoutData);
  const checkoutIsLoading = useSelector(CheckoutData);
  const checkoutsuccess = useSelector(checkoutSuccess);
  const checkourError = useSelector(CheckoutError);
  const orderData = useSelector(OrderData);
  const orderIsLoading = useSelector(OrderLoading);
  const orderError = useSelector(OrderError);
  const [cardName, setCardName] = useState("");
  const params = useParams();
  const history = useHistory();
  const [loadingOrder, setLoadingOrder] = useState<boolean | undefined>(
    undefined
  );

  // const stripePromise = loadStripe(
  //   "pk_test_51Oj0UzBewB9fi7jpC6ugF499uT0L0WTGXxgqChafymvegad6QDXqZUjUtBMiV6DZ1gCwN8fumQNL9Cs77FbTkPIU00XSUJ5Yj9"
  // );

  const stripe = useStripe();
  const elements = useElements();

  const handleFirstname = (e: string) => {
    setFirstName(e);
  };
  const handleLastname = (e: string) => {
    setLastName(e);
  };
  const handleCountry = (e: string) => {
    setCountry(e);
  };
  const handleStreetAddress = (e: string) => {
    setStreetAddress(e);
  };
  const handleCity = (e: string) => {
    setCity(e);
  };
  const handlePostCode = (e: string) => {
    setPostCode(e);
  };

  const handlePhone = (e: string) => {
    setPhone(e);
  };
  const handleEmail = (e: string) => {
    setEmail(e);
  };

  const handleCardName = (e: string) => {
    setCardName(e);
  };

  const getPaymentToken = async (orderId: number) => {
    const response = await wooApi.get(`orders/${orderId}`);
    console.log("the value from getPaymentToken: " + JSON.stringify(response));
    const paymentToken = response.data.meta_data.find(
      (meta: any) => meta.key === "_stripe_token"
    ).value;
    return paymentToken;
  };

  const handleSubmitCheckOut = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log("the condition 1 is null");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement === null) {
      console.log("the card element is null");
      return;
    }

    const { error, token } = await stripe.createToken(cardElement);
    if (token != undefined) setToken(token.id);
    console.log("the error of token is : " + JSON.stringify(error));
    let obj: OrderType = {
      products: [product],
      first_name: first_name,
      last_name: last_name,
      country: country,
      street_address: streetAddress,
      post_code: +postCode,
      city: city,
      phone_number: phone,
      email: email,
      user_id: user.user.id,
      amount: checkoutdata.amount,
      // order_id: orderData != undefined ?  orderData.result,
      token: token?.id,
    };
    console.log("the data is : " + JSON.stringify(obj));
    if (
      first_name === "" ||
      last_name === "" ||
      country === "" ||
      streetAddress === "" ||
      city === "" ||
      postCode === "" ||
      phone === "" ||
      email === ""
    ) {
      setError("Error: Please fill all required fields");
    } else {
      if (token != undefined) {
        store.dispatch(postOrder(obj));

        console.log("you enter to checkout: " + orderData.order_id);
        setTimeout(() => {}, 1000);
      }
    }
  };

  const onDownloadHandler = () => {
    if (checkoutdata.list_items != undefined)
      store.dispatch(
        downloadArchive({
          product_id: checkoutdata?.list_items[0].id,
          user_id: user.user.id,
        })
      );
  };

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        border: "0.5pt solid #f0a0a5",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      iconStyle: "default",
    },
  };

  useEffect(() => {
    // if (checkoutsuccess) {
    //   history.push("/cart");
    // }
    if (checkourError != "") setError(checkourError);
  }, [checkoutsuccess]);

  // useEffect(() => {
  //   if (checkoutsuccess)
  //     store.dispatch(
  //       putOrder({ order_id: orderData.order_id, status: "Completed" })
  //     );
  //   else putOrder({ order_id: orderData.order_id, status: "Cancelled" });
  // }, [checkoutsuccess]);

  useEffect(() => {
    if (
      orderIsLoading == false &&
      orderError == "" &&
      orderData.order_id != undefined
    ) {
      store.dispatch(
        checkoutThunk({
          name: product.short_description,
          email: email,
          token: token,
          amount: checkoutdata.amount,
          order_id: orderData.order_id,
        })
      );
    }
  }, [token, orderIsLoading, ]);

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />

        <section className="single-post-section">
          <div className="container-xxl">
            <form onSubmit={handleSubmitCheckOut}>
              <div className="row">
                <div className="col-lg-8 bg-white p-5">
                  <div className="post-content">
                    <div className="comments-box" id="comments">
                      {/*<h3>Checkout</h3>*/}

                      {error != "" && (
                        <div className={"bg-danger text-center"}>
                          <h6 className={"text-white py-3"}>{error}</h6>
                        </div>
                      )}
                      <div className={"row"}>
                        <div className={"col-6"}>
                          <TextField
                            id={"firstname"}
                            name={"firstname"}
                            placeholder={"First name"}
                            type={"text"}
                            onChange={handleFirstname}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-6"}>
                          <TextField
                            id={"lastname"}
                            name={"lastname"}
                            placeholder={"Last name"}
                            type={"text"}
                            onChange={handleLastname}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"country"}
                            name={"country"}
                            placeholder={"Country"}
                            type={"text"}
                            onChange={handleCountry}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"streeaddress"}
                            name={"streeaddress"}
                            placeholder={"Street Address"}
                            type={"text"}
                            onChange={handleStreetAddress}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"city"}
                            name={"city"}
                            placeholder={"City"}
                            type={"text"}
                            onChange={handleCity}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"postcode"}
                            name={"postcode"}
                            placeholder={"Post Code"}
                            type={"number"}
                            onChange={handlePostCode}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"phone"}
                            name={"phone"}
                            placeholder={"Phone Number"}
                            type={"tel"}
                            onChange={handlePhone}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"col-12"}>
                          <TextField
                            id={"email"}
                            name={"email"}
                            placeholder={"Email Address"}
                            type={"email"}
                            onChange={handleEmail}
                            className={"inputcheck"}
                          />
                        </div>
                        <div className={"pt-5 mt-5 row"}>
                          <h4 className="mb-4">PAYMENT DETAILS</h4>
                          {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
                          <div className={"col-12"}>
                            <TextField
                              id={"cardHolder"}
                              name={"CardHolder"}
                              placeholder={"Card name holder"}
                              type={"text"}
                              label=""
                              onChange={handleCardName}
                              className={"inputcheck"}
                            />
                          </div>
                          <div className={"col-12"}>
                            <CardNumberElement
                              options={ELEMENT_OPTIONS}
                              className="input_payment"
                            />
                          </div>
                          <div className={"col-2"}>
                            <CardExpiryElement className="input_payment" />
                          </div>
                          <div className={"col-2"}>
                            <CardCvcElement className="input_payment" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-5">
                  <div className="sidebar">
                    <div className="sidebar-widget widget_categories">
                      <h4>Check Out</h4>
                      <div className="d-flex justify-content-between">
                        <b>items:</b> <p> {checkoutdata.list_items?.length} </p>
                      </div>
                      <div>
                        {checkoutdata.list_items?.map((item) => (
                          <div className="d-flex justify-content-between">
                            <p>{item.name}</p>{" "}
                            <p>{item.sale_price.toPrecision(3)}$</p>
                          </div>
                        ))}
                      </div>
                      <h5 className="d-flex justify-content-between mb-4">
                        <div>Total price:</div>
                        <div>{checkoutdata.amount?.toPrecision(4)}$ </div>
                      </h5>
                      {checkoutsuccess == false ? (
                        <button
                          type="submit"
                          id="submit_contact"
                          disabled={!stripe}
                          className={"button-one"}
                        >
                          {loadingOrder == true ? (
                            <img
                              src={loadingimg}
                              alt={"loading-image"}
                              className="imageloop"
                            />
                          ) : (
                            <span>Place Order</span>
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          id="submit_contact"
                          onClick={onDownloadHandler}
                          className={"button-one"}
                        >
                          {loadingOrder == true ? (
                            <img
                              src={loadingimg}
                              alt={"loading-image"}
                              className="imageloop"
                            />
                          ) : (
                            <span>download</span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Checkout;
