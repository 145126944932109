import {ProductItem, Product, Products } from "../models/product";
import {CustomerNode, UserNode} from "../models/user";
import {Archive, Categories, Images} from "../models/Category";
import {OrderN, OrderNode} from "../models/Order";
import {OnePostNode, PostNode} from "../models/Post";
import {CartNode} from "../models/Cart";
import {CommentsNode} from "models/Comment";


export const intialCategoriesState: Categories = {
    data: [],
    error: "",
    isLoading: true,
}

export const intialImagesState: Images = {
    data: [],
    error: "",
    uploadProgress: 0,
    isLoading: true,
}

export const intialArchiveState: Archive = {
    data: [],
    error: "",
    isLoading: true,
    uploadProgress: 0
}

export const intialProductsState: Products = {
    data: [],
    direction: 0,
    currentProductIndex: null,
    error: "",
    isLoading: true,
}

export interface checkoutModel {
    items?:Array<Product>,
    totalPrice: number,
    totalItems: number
}


export interface CheckoutNode {
    data: checkoutModel,
    error?: string,
    isLoading: boolean
}

export const intialCheckOutState: CheckoutNode = {
    data: {
        items: [],
        totalItems: 0,
        totalPrice: 0,
    },
    error: "",
    isLoading: true,
}


export const intialCartState: CartNode = {
    data: [],
    totalePrice:0,
    count: 0,
    error: "",
    isLoading: true,
    access: false,
}

export const intialProduct: ProductItem = {
    data: {
        id:0,
        name: "",
        images: [],
        short_description: "",
        categories: [],
        sale_price: 0,
        regular_price: 0,
        rank:0,
    },
    isLoading: true,
    error: "",
}

export const intialAuthState: UserNode = {
    
    data: {
        user: {
            id: -1,
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            phone_number: "",
            date_created: "",
            date_modified: "",
            is_admin: 0,
        },
        token: ""
    },
    error: "",
    isLogin: false,
    isLoading: true,
}

export const intialCustomerState: CustomerNode = {
    data: {},
    error: "",
    isLoading: true,
}

export const intialOrderState: OrderN = {
    data : {},
    error: "",
    isLoading: false

}

export const intialOrdersState: OrderNode= {
    data: [],
    error: "",
    isLoading: true,
    statusCode: ""
}

export const intialPostsState: PostNode = {
    data: [],
    error: "",
    isLoading: true,
    statusCode: "",
    currentPage: 1
}

export const intialPostState: OnePostNode = {
    data: {},
    error: "",
    isLoading: true,
    statusCode: "",
}

export const intialCommentsState: CommentsNode = {
    data: [],
    error: "",
    isLoading: true,
}



