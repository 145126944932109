import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {intialArchiveState} from "../intialState";
import {RootState} from "../index";
import { Archive, Images } from "../../models/Category";
import { addArchiveThunk } from "store/Thunks/ArchiveThunks";

const ArchiveSlice = createSlice({
    name: "archive",
    initialState: intialArchiveState,
    reducers: {
        setUploadProgressArchive: (state, action) => {
        state.uploadProgress = action.payload;
    }
},
    extraReducers: (builder) => {
        builder
            .addCase(addArchiveThunk.pending, (state: Archive) => {
                state.isLoading = true
            })
            .addCase(addArchiveThunk.fulfilled, (state: Archive, action: PayloadAction<any>) => {
                state.isLoading = false
                console.log("the file name is : "+ JSON.stringify(JSON.stringify(action.payload)))
                state.data = action.payload
            })
            .addCase(addArchiveThunk.rejected, (state: Archive, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })

    }
})

export const {setUploadProgressArchive} = ArchiveSlice.actions

export const ArchiveStatus = (state: RootState): boolean =>
    state.archive.isLoading

export const ArchiveUploadProgress = (state: RootState): number =>
    state.archive.uploadProgress

export const ArchiveData = (state: RootState): any =>
    state.archive.data

export const ArchiveError = (state: RootState): string =>
    state.archive.error

export default ArchiveSlice.reducer