import React from "react"


interface Params {
    id?: string,
    value: string,
    type?:  "button" | "submit" | "reset" | undefined,
    onClick?: React.MouseEventHandler
}

export const Button:React.FC<Params> = ({
    id,
    value,
    type,
    onClick
                                        }): JSX.Element => {
    return (
        <button type={type} id={id} onClick={onClick}>
            {value}
        </button>
    )
}