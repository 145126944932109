import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User, UserNode} from "../../models/user";
import store, {RootState} from "../index";
import {addNewUser, fetchUser, logOut, updateUser, validateToken} from "../Thunks/AuthThunks";
import {intialAuthState} from "../intialState";
import { fetchCartThunk } from "store/Thunks/CartThunks";
import { fetchOrderThunk } from "store/Thunks/OrderThunks";



const UserSlice = createSlice({
    name: "User",
    initialState: intialAuthState,
    reducers: {
        InitAuth: (state: UserNode) => {
            // let user:UserNode = intialAuthState
            let AuthString = localStorage.getItem("token")
            if (AuthString == undefined)
            {
                console.log("you have nothing in storage")
            }
            else {
                state.data = JSON.parse(AuthString) as User
                // console.log("the data from  init is : " + JSON.stringify(state.data))
                // state.token = 
                // state.isLogin = true
                // state.isLoading = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateToken.pending, (state: UserNode) => {
                state.isLoading = true
            })
            .addCase(validateToken.fulfilled, (state: UserNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.isLogin = true
            })
            .addCase(validateToken.rejected, (state: UserNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.isLogin = false
            })
            .addCase(fetchUser.pending, (state: UserNode) => {
                state.isLoading = true
            })
            .addCase(fetchUser.fulfilled, (state: UserNode, action: PayloadAction<User>) => {
                state.isLoading = false
                console.log("the data is : "+ action.payload)
                state.data = action.payload
                state.isLogin = true
            })
            .addCase(fetchUser.rejected, (state: UserNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.isLogin = false
                state.error = "Username or Password is not correct!"
            })
            .addCase(addNewUser.pending, (state: UserNode) => {
                state.isLoading = true
            })
            .addCase(addNewUser.fulfilled, (state: UserNode, action: PayloadAction<User>) => {
                state.isLoading = false
            })
            .addCase(addNewUser.rejected, (state: UserNode, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(logOut.fulfilled, (state:UserNode) =>{
                state.isLoading = true
            })
            .addCase(logOut.pending, (state:UserNode)=>{
                state.isLogin = false
                state.isLoading = false
            })
            .addCase(logOut.rejected, (state:UserNode)=>{
                state.isLoading = false
                state.isLogin = true
            })
            .addCase(updateUser.pending, (state:UserNode) => {
                state.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state:UserNode, action:PayloadAction<any>) => {
                state.isLoading = false
                state.data.user = action.payload
            })
    },
})

export const { InitAuth } =
    UserSlice.actions
export const UserData = (state: RootState): User =>
    state.user.data

export const UserError = (state: RootState): string => {
    if (state.user === undefined)
        return ""
    else
        return state.user.error
}

// export const UserToken = (state: RootState): string =>
//     state.user.data.token

export const UserLoading = (state: RootState): boolean => {
    if (state.user === undefined)
        return false
    else
        return state.user.isLoading
}

export const UserLogin = (state: RootState) : boolean => {
    if (state.user === undefined)
        return false
    else {

        return state.user.isLogin
    }
}

export default UserSlice.reducer