
interface Params {
    title: string,
    description?: string,
    iconClass?: string,
    colClass?: string,
}
export const InfoBox:React.FC<Params> =({
    title,
    description,
    iconClass,
    colClass,
                                        }): JSX.Element =>{
    return (
        <div className={colClass}>
            <div className="contact-info-post">
                <i className={iconClass}></i>
                <div className="info-content">
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}