import { TextArea, TextField } from "components/atoms/TextField";
import Footer from "components/sections/Footer";
import Header from "components/sections/Header";
import { Link } from "react-router-dom";
import AddNewCategory from "../../pages/customer/AddNewCategory";
import { useSelector } from "react-redux";
import { useState } from "react";
import { addCategoryAsync } from "store/Thunks/CategoryThunks";
import { ImagesData, ImagesStatus } from "store/slices/ImageSlice";
import store from "store";
import MultiFileUpload from "components/molecules/MutiFilesUpload";
import image from "../../assets/images/Frame 11.jpg";
import CategoryTable from "components/molecules/CategoryTable";

const AddCategory = (): JSX.Element => {
  const [categoryName, setCategoryName] = useState("");
  const isLoadingCate = useSelector(ImagesStatus);
  const imageData = useSelector(ImagesData);
  const [error, setError] = useState<string | undefined>("");
  const [success, setSuccess] = useState<string | undefined>("");

  const handleCategoryName = (e: string) => setCategoryName(e);

  const createHandler = () => {
    if (
      isLoadingCate == false &&
      categoryName !== "" &&
      imageData.result[0] !== undefined
    ) {
      console.log("the value from add Category: " + imageData.result[0]);
      store.dispatch(
        addCategoryAsync({
          src: imageData.result[0],
          name: categoryName,
        })
      );
      setSuccess("Category is Created");
      setError(undefined);
    } else {
      setError("Category Is Not Created !");
      setSuccess(undefined);
    }
  };

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className={"container"}></section>

        <section className="single-project-section">
          <div className="container">
            <div className="card border-0">
              <div className="card-body p-4">
                <h5 className="card-title">Add New Category</h5>
                <hr />

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div className="w-75">
                    <div className="border-1 p-4 rounded">
                      <div className="">
                        {error !== undefined && error !== "" && (
                          <h4 className="bg-light text_error p-4 my-4">
                            {error}
                          </h4>
                        )}
                        {success !== undefined && success !== "" && (
                          <h4 className="bg-light text_success p-4 my-4">
                            {success}
                          </h4>
                        )}
                      </div>
                      <div className="">
                        <TextField
                          id={"category_name"}
                          name={"category_name"}
                          placeholder={"Category Name"}
                          type={"text"}
                          className={"inputcheck"}
                          onChange={handleCategoryName}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <MultiFileUpload
                          key={"upload-images-03"}
                          title="upload Image"
                          type={3}
                        />
                        <div className="">
                          <Link
                            to={"#"}
                            className="theme-button"
                            onClick={createHandler}
                          >
                            <span>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex flex-row justify-content-around">
                        <CategoryTable />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"mt-5 d-flex justify-content-center"}>
                  <div className="mx-4">
                    <Link to={"/"} className="theme-button">
                      <span>Home</span>
                    </Link>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default AddCategory;