import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {Order, OrderNode} from "../../models/Order";
import {deleteOrder, fetchOrderThunk, postOrder, putOrder} from "../Thunks/OrderThunks";
import {intialOrdersState} from "../intialState";



export const OrdersSlice = createSlice({
    name: "orders",
    initialState: intialOrdersState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderThunk.pending, (state: OrderNode) => {
                state.isLoading = true
            })
            .addCase(fetchOrderThunk.fulfilled, (state: OrderNode, action: PayloadAction<Array<Order>>) => {
                // console.log("the error is launch \n\n : "+ JSON.stringify(action.payload))
                state.isLoading = false
                state.data = action.payload
                state.error = ""
            })
            .addCase(fetchOrderThunk.rejected, (state: OrderNode) => {
                state.isLoading = false
                state.error = "Error in fetching data !"
            })
            .addCase(postOrder.fulfilled, (state: OrderNode, action: PayloadAction<Order>) => {
                state.isLoading = false
                state.data.push(action.payload);
            })
            
    }
})

export const OrdersStatus = (state: RootState): string =>
    state.orders.statusCode
export const OrdersData = (state: RootState): Array<Order> =>
    state.orders.data
export const OrdersError = (state: RootState): string =>
    state.orders.error
export const OrdersLoading = (state: RootState): boolean =>
    state.orders.isLoading

export default OrdersSlice.reducer