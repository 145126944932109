import logo from "../../assets/images/logo-image.svg";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  UserError,
  UserLoading,
  UserLogin,
} from "../../store/slices/UserSlice";
import { fetchUser } from "../../store/Thunks/AuthThunks";
import store from "../../store";
import Footer from "../../components/sections/Footer";

const Welcome = (): JSX.Element => {
  const history = useHistory();
  const [uname, setUname] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setError] = useState("");
  let isLoading: boolean = useSelector(UserLoading);
  let isLogin: boolean = useSelector(UserLogin);
  let error: string = useSelector(UserError);
  const [isWaiting, setIsWaiting] = useState(false);
  // const dispatch = useDispatch()

  const handleUname = (e: any) => {
    setUname(e);
  };

  const handlePassword = (e: any) => {
    setPassword(e);
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(fetchUser({ username: uname, password: password }));
    setIsWaiting(true);
    // while (isLogin != true);
    console.log("you enter to submit handler : " + isLogin);
    // while (state.isLogin == true);
  };

  useEffect(() => {
    if (isLogin == true) {
      console.log("the user is login");
      history.push("/");
    }
    console.log("the state of is Login is : " + isLogin);
    // store.dispatch(LogOut())
  }, [isLoading]);

  return (
    <div id="" className="ip-container">
      {/*<Loader />*/}
      <div id="">
        <div id="">
          <section className="mb-5">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {/*{*/}
              {/*    (error != '') && (*/}
              {/*        <div className={"bg-danger text-center"}>*/}
              {/*            <h6 className={"text-white py-3"}>{error}</h6>*/}
              {/*        </div>*/}
              {/*    )*/}
              {/*}*/}
              <div className="h-100">
                <div className="row g-0 h-100">
                  <div className="">
                    {/*<h3> Login </h3>*/}
                    <div className="d-flex flex-column justify-content-center align-items-center h-50">
                      {/*<div className="col-md-6">*/}
                      <h2>Welcome to Sab Print</h2>
                      <p>Please verify your E-mail address before Login</p>
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default Welcome;
