import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {intialCategoriesState} from "../intialState";
import {RootState} from "../index";
import {addCategoryAsync, deleteCategoryAsync, fetchCategoriesAsync} from "../Thunks/CategoryThunks";
import {Categories, Category} from "../../models/Category";
import { postReturn } from "models/result";

const CategoriesSlice = createSlice({
    name: "categories",
    initialState: intialCategoriesState,
    reducers: {
        GetCategoryById: (state:Categories, action: PayloadAction<number>) => {
            state.data.filter(item => item.category_id == action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesAsync.pending, (state: Categories) => {
                state.isLoading = true
            })
            .addCase(fetchCategoriesAsync.fulfilled, (state: Categories, action: PayloadAction<Array<Category>>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCategoriesAsync.rejected, (state: Categories) => {
                state.isLoading = false
                state.error = "the error : rejected"
            })
            .addCase(addCategoryAsync.pending, (state: Categories) => {
                state.isLoading = true
                state.error = ""
            })
            .addCase(addCategoryAsync.fulfilled, (state: Categories, action: PayloadAction<Category>) => {
                state.isLoading = false
                state.data.push(action.payload)
                state.error = ""
            })
            .addCase(addCategoryAsync.rejected, (state: Categories, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(deleteCategoryAsync.pending, (state: Categories) => {
                state.isLoading = true
                state.error = ""
            })
            .addCase(deleteCategoryAsync.fulfilled, (state: Categories, action: PayloadAction<postReturn> ) => {
                state.isLoading = false
                state.data = state.data.filter(item => item.category_id != action.payload.result)
                state.error = ""
            })
            .addCase(deleteCategoryAsync.rejected, (state: Categories, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
    }
})

export const { GetCategoryById } =
    CategoriesSlice.actions
  

export const CategoriesStatus = (state: RootState): boolean =>
    state.categories.isLoading

export const CategoriesData = (state: RootState): Array<Category> =>
    state.categories.data

export const CategoriesError = (state: RootState): string =>
    state.categories.error
export default CategoriesSlice.reducer