import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerError } from "models/error";
import { axiosNew } from "services/api";
import { setUploadProgressArchive } from "store/slices/ArchiveSlice";

export const addArchiveThunk = createAsyncThunk<any,any,
{
    rejectValue:ServerError
}
>(
    'Archive/CreateArchive',
    async (params ,{dispatch, rejectWithValue}): Promise<any> => {
        try {
            // const storage = await localStorage.getItem("token")
            // if (storage == undefined)
            //     return rejectWithValue({message: "Please Login", status: 403})
            // let user:User = JSON.parse(storage) as User
            // console.log("the data from cart Thunk is: "+ JSON.stringify(user))
            
            const {data}  = await axiosNew.post(`/archive/upload`,params, {

                onUploadProgress: (progressEvent: any) => {
                    const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total);
                    console.log("the progress is : "+ progress)
                    dispatch(setUploadProgressArchive(progress))
                    
            },
            })
            return data
        }
        catch ( error:any )
        {
            if (!error)
                {
                    console.log("you have an error in uploading ....")
                throw error
                }
                console.log("you enter to catch error.....")
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)