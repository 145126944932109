import {createAsyncThunk} from "@reduxjs/toolkit";
import { axiosNew} from "../../services/api";
import { ServerError } from "models/error";
import { User } from "models/user";
import { CartDescriptor } from "@stripe/stripe-js";
import { CartItem } from "models/Cart";





export const fetchCartThunk = createAsyncThunk<
any,
void,
{
    rejectValue:ServerError
}
>(
    'Cart/CreateCart',
    async (_,{rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.get(`/cart/${user.user.id}`, {
                headers: {
                    "Authorization" : "Basic "+user.token
                } 
            })
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

export const AddProductToCartThunk = createAsyncThunk<
any,
CartItem,
{
    rejectValue:ServerError
}
>(
    'Cart/AddProductCart',
    async (cartData,{rejectWithValue} ): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const { data } = await axiosNew.post('/cart', {
                headers:{
                    "Authorization" : "Basic "+user.token
                },
                data: cartData,
            })
            return cartData
        }
        catch ( error )
        {
            return rejectWithValue({message: "Please Login", status: 403})
        }
    }
)

export const DeleteItemFromCartThunk = createAsyncThunk<
any,
number,
{
    rejectValue:ServerError
}
>(
    'Cart/DeleteItemFromCart',
    async (cart_id, {rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const { data } = await axiosNew.delete(`/cart/${cart_id}`,{
                headers:{
                    "Authorization" : "Basic "+user.token
                },
            })
            return cart_id
        }
        catch ( error )
        {
            return rejectWithValue({message: "Please Login", status: 403})
        }
    }
)