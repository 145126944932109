import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {deleteProductsAsync, fetchProductByIdAsync, PostProduct} from "../Thunks/ProductThunks";
import { RootState } from "../index";
import {intialProduct} from "../intialState";
import {ProductItem, Product} from "../../models/product";


const productIdGetSlice = createSlice({
    name: "productById",
    initialState: intialProduct,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductByIdAsync.pending, (state: ProductItem) => {
                state.isLoading = true
            })
            .addCase(fetchProductByIdAsync.fulfilled, (state: ProductItem, action: PayloadAction <Product>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchProductByIdAsync.rejected, (state: ProductItem) => {
                state.isLoading = false
                state.error = "the error : rejected"
            })
            .addCase(PostProduct.pending, (state: ProductItem) => {
            state.isLoading = true
        })
            .addCase(PostProduct.fulfilled, (state: ProductItem, action: PayloadAction <Product>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(PostProduct.rejected, (state: ProductItem) => {
                state.isLoading = false
                state.error = "the error : rejected"
            })
    }
})

export const ProductIdData = (state: RootState): Product =>
    state.productById.data
export const ProductIdStatus = (state: RootState): boolean =>
    state.productById.isLoading
export const ProductIdError = (state: RootState): string =>
    state.productById.error

export default productIdGetSlice.reducer