import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CartData } from "../../store/slices/CartSlice";
import { IoIosNotificationsOutline } from "react-icons/all";
import OneSignal from "react-onesignal";
const Notification = () => {
  // const [items, setItems] = useState<Array<ProductModel> | undefined>()
  // const [totalPrice, setTotalPrice] = useState<number>(0)
  const items = useSelector(CartData);
  const history = useHistory();
  // const customer = useSelector(CustomerData)
  // const isLoading = useSelector(CartStatus)
  const [initialized, setInitialized] = useState(false);

  const handleCheckOut = () => {
    // store.dispatch(AddToCheckout(items.data))
    history.push("/checkout");
  };

  useEffect(() => {
    OneSignal.init({
      appId: "93dd6941-7804-447c-b797-31a1a7e45b2f",
      allowLocalhostAsSecureOrigin: true,
    }).then(() => {
      setInitialized(true);
      OneSignal.showSlidedownPrompt().then((e) => {
        console.log("the data from one signal: " + JSON.stringify(e));
        // do other stuff
      });
    });
    // const carts =  localStorage.getItem("cart")
    // if (carts != null)
    //     setItems(JSON.parse(carts))
    // let result:number = 0
    // items?.map((item) => {
    //     result = +result + +(item.price != undefined ? item.price : 0)
    //     // return (item.price != undefined ? item.price : 0);
    // })
    // setTotalPrice(result)
  }, [initialized]);

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className="single-post-section">
          <div className="container">
            <div className="row bg-white">
              <div className="p-5">
                <div className="post-content">
                  <div className="comments-box" id="comments">
                    <h3 className={"mb-5"}>Notification</h3>
                    <ul className="comments-list">
                      <div></div>
                      <li className="notification-container d-flex flex-row">
                        <div
                          className={"icon d-flex align-content-center mx-4"}
                        >
                          <IoIosNotificationsOutline size={40} />
                        </div>
                        <div className="notification-content d-flex flex-column">
                          <h3>Notification title</h3>
                          <p>some notification </p>
                        </div>
                      </li>
                      <li className="notification-container d-flex flex-row ">
                        <div
                          className={"icon d-flex align-content-center mx-4"}
                        >
                          <IoIosNotificationsOutline size={40} />
                        </div>
                        <div className="notification-content d-flex flex-column">
                          <h3>Notification title</h3>
                          <p>some notification </p>
                        </div>
                      </li>
                      <li className="notification-container d-flex flex-row">
                        <div
                          className={"icon d-flex align-content-center mx-4"}
                        >
                          <IoIosNotificationsOutline size={40} />
                        </div>
                        <div className="notification-content d-flex flex-column">
                          <h3>Notification title</h3>
                          <p>some notification </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Notification;
