import logo from "../../assets/images/logo-image.svg";
import { TextField } from "../../components/atoms/TextField";
import { useHistory, useParams } from "react-router-dom";
import Footer from "../../components/sections/Footer";
import { useEffect, useState } from "react";
import { axiosApi } from "../../services/api";
import loadingimg from "../../assets/images/loading-circal.png";

interface Params {
  email: string;
}
const SetPassword = (): JSX.Element => {
  const [pin, setPin] = useState("");
  const [passowrd, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [validate, setValidate] = useState(false);
  const history = useHistory();
  const params: Params = useParams();
  const [error, setError] = useState("");

  const handlePin = (e: string) => {
    setPin(e);
  };

  const handlePassword = (e: any) => {
    setPassword(e);
  };
  const handlecPassword = (e: any) => {
    setcPassword(e);
  };
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsWaiting(true);
    if (validate === true) {
      if (passowrd !== cpassword && validate === true)
        setError("please confirm your password");
      else {
        axiosApi
          .post("bdpwr/v1/set-password", {
            email: params.email,
            code: pin,
            password: passowrd,
          })
          .then((data) => {
            setIsWaiting(false);
            setError("");
          })
          .catch((e) => {
            setError("Your pin is note correct!");
            setIsWaiting(false);
          });
      }
    } else {
      axiosApi
        .post("bdpwr/v1/validate-code", { email: params.email, code: pin })
        .then((data) => {
          setValidate(true);
          setIsWaiting(false);
          setError("");
        })
        .catch((e) => {
          setError("Your pin is note correct!");
          setIsWaiting(false);
        });
    }

    // console.log("the data is : "+ JSON.stringify(data))

    // store.dispatch(fetchUser({ username: uname, password: password }))
    // while (isLogin != true);
    // console.log("you enter to submit handler : " + isLogin)
    // while (state.isLogin == true);
  };

  useEffect(() => {
    // if (validate == true)
    console.log(`the status of : {$validate} | {$isWaiting}`);
    if (validate === true && isWaiting === false) {
      history.push("/login");
    }
  }, [validate]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="content">
          <section className="contact-info-section">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {error !== "" && (
                <div className={"bg-danger text-center"}>
                  <h6 className={"text-white py-3"}>{error}</h6>
                </div>
              )}
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    {validate === false ? (
                      <form id="contact-form" onSubmit={onSubmitHandler}>
                        {/*<h3> Login </h3>*/}
                        <div className="row d-flex justify-content-center ">
                          <div className="col-md-6">
                            <p>E-mail : {params.email}</p>
                            <TextField
                              id={"pin"}
                              name={"pin"}
                              placeholder={"Pin"}
                              type={"number"}
                              onChange={handlePin}
                            />
                            <br />
                            <br />
                            <br />
                            <button type="submit" id="submit_contact">
                              {isWaiting === true ? (
                                <img
                                  src={loadingimg}
                                  alt={"loading-image"}
                                  className="imageloop"
                                />
                              ) : (
                                "Verify"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form id="contact-form" onSubmit={onSubmitHandler}>
                        {/*<h3> Login </h3>*/}
                        <div className="row d-flex justify-content-center ">
                          <div className="col-md-6">
                            <p>E-mail : {params.email}</p>

                            <TextField
                              id={"password"}
                              name={"password"}
                              placeholder={"New Password"}
                              type={"password"}
                              onChange={handlePassword}
                            />
                            <TextField
                              id={"cpassword"}
                              name={"cpassword"}
                              placeholder={"Confirm Password"}
                              type={"password"}
                              onChange={handlecPassword}
                            />
                            <br />
                            <br />
                            <br />
                            <button type="submit" id="submit_contact">
                              {isWaiting === true ? (
                                <img
                                  src={loadingimg}
                                  alt={"loading-image"}
                                  className="imageloop"
                                />
                              ) : (
                                "Update Password"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default SetPassword;
