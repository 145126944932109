import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "store";
import ScrollToTop from "./ScrollTop";
import { fetchProductsAsync } from "./store/Thunks/ProductThunks";
// import {InitAuth} from "./store/slices/UserSlice";

// store.dispatch(InitAuth())

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
