import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosApi, axiosNew, wooApi} from "../../services/api";
import {WooRestApiEndpoint} from "woocommerce-rest-ts-api/dist/src/typesANDinterfaces";
import {Product} from "../../models/product";
import {PostModel} from "../../models/Post";
import { CommentModel } from "models/Comment";
import { ServerError } from "models/error";





// 0667784512 mohammed


// ==================== get Products
// type postParams = {
//     name: string,
//     slug: string,
//     type: string,
//     description: string,
//     short_description: string,
//     regular_price: number,
//     sale_price?: number,
//     virtual: boolean
//     downloadable: boolean,
//     download:any,
//     shipping_required: boolean,
//     categories: any,
//     tags: any,
//     category: number
// }

type paramsSearch = {
    category?: number,
    tag?:number,
    search?: string,
    per_page?: number,
    min_price?: number,
    max_price?: number,
    order_by?: string
    // price?: number,
}

export const fetchProductsAsync = createAsyncThunk<any, paramsSearch | undefined, {
    rejectValue:ServerError
}>(
    'products/fetchRecentProducts',
    async (params, {rejectWithValue}): Promise<any> => {
         let obj = {
            category: params?.category || null,
            search: params?.search || null,
            min_price: params?.min_price || null,
            max_price: params?.max_price || null,
            order: "desc",
            per_page: params?.per_page || null,
        };
        try{
        const { data }  = await axiosNew.get("/products",{ params: obj})
        return data
    }
    catch(error:any)
    {
        console.log("you have an error in fetch products")
        return rejectWithValue({message: error.message, status: error.status})
        // return error
    }
    }
)


export const deleteProductsAsync = createAsyncThunk<any, number,
{
    rejectValue:ServerError
}>(
    'product/deleteProducts',
    async (id, {rejectWithValue}): Promise<number> => {

        try{
        const { data }  = await axiosNew.delete("/products/"+id)
        return id
    }
    catch(error:any)
    {
        rejectWithValue({message: error.message, status:error.status})
        return error.message
    }
    }
)

// create Thunk fetch Product
export const fetchProductByIdAsync = createAsyncThunk<
    any, number , {rejectValue:ServerError}
>(
    'products/fetchProductById',
    async (productId, {rejectWithValue}) : Promise<Product> => {
        try {
            const {data} = await axiosNew.get(`/products/${productId}`)
            return data
        }
        catch(error:any) 
        {
            rejectWithValue({message: error.message, status: error.status})
            return error.message
        }
    }
)



export const PostProduct = createAsyncThunk<any, Product, {rejectValue:ServerError}>(
    'product/PostPorduct',
    async (obj: Product, {rejectWithValue}): Promise<any> => {

         try{
        const data = await axiosNew.post("/products", {
            data:obj
        })
        return obj
    }
    catch(error:any)
    {
        rejectWithValue({message: error.message, status: error.status})
        return error.message
    }
    }
)
