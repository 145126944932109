import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import store from "store";
import {
  deleteProductsAsync,
  fetchProductByIdAsync,
} from "store/Thunks/ProductThunks";
import { fetchReviewThunk } from "store/Thunks/ReviewThunks";
import { ProductsData, ProductsStatus } from "store/slices/ProductsSlice";
// import imagexx from "/Users/ceo/Desktop/backups_2024/sabprint-backend/upload/4769485Screenshot 2024-05-28 at 10.58.31.png";
// import image from "/Users/ceo/Desktop/backups_2024/sabprint-backend/upload";

type params = {
  id?: number;
  name?: string;
  image?: string;
  price?: number;
  short_description?: string;
  onDelete: () => void;
  onEdit: () => void;
};

const TableRow: React.FC<params> = ({
  id,
  name,
  image,
  price,
  short_description,
  onDelete,
  onEdit,
}): JSX.Element => {
  const fetchProductId = (id?: number) => {
    if (id != undefined) {
      store.dispatch(fetchProductByIdAsync(id));
      store.dispatch(fetchReviewThunk(id));
    }
  };
  return (
    <tr>
      <td>
        <p>{id}</p>
      </td>
      <td>
        <Link
          to={id == -1 ? "/home" : "/product/" + id}
          onClick={() => fetchProductId(id)}
        >
          <img src={image} width={120} />
        </Link>
      </td>
      <td>
        <p>{name}</p>
      </td>
      <td>
        <p>{short_description}</p>
      </td>
      <td>
        <p>{price}</p>
      </td>
      <td className="">
        <div className="d-flex flex-column ">
          <button className="hyperlink" onClick={onDelete}>
            Delete
          </button>
          {/* <button className="hyperlink" onClick={onEdit}>
            Edit
          </button> */}
        </div>
      </td>
    </tr>
  );
};

const ProductTable = (): JSX.Element => {
  const isLoading = useSelector(ProductsStatus);
  const products = useSelector(ProductsData);
  const history = useHistory();

  const deleteHandler = (id?: number) => {
    if (id != undefined) store.dispatch(deleteProductsAsync(id));
  };

  const editHandler = (id?: number) => {
    if (id != undefined) {
      store.dispatch(fetchProductByIdAsync(id));
      history.push("/product-edit/" + id);
    }
  };

  return (
    <div className="table-responsive table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Product Id</th>
            <th> Image </th>
            <th> Name </th>
            <th> Description </th>
            <th> price </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {isLoading == false &&
            products.map((item) => {
              return (
                // <p>hellow rodl!</p>
                <TableRow
                  key={"item-key-" + item.id}
                  id={item.id}
                  name={item.name}
                  short_description={item.short_description}
                  image={item.images[0].src}
                  price={item.sale_price}
                  onDelete={() => deleteHandler(item?.id)}
                  onEdit={() => editHandler(item?.id)}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
