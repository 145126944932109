import Footer from "../../components/sections/Footer";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/sections/Header";
import CategoriesDropDownList from "../../components/molecules/CategoriesDropDownList";
import loadingimg from "../../assets/images/loading-circal2.png";
import SwiperCore from "swiper";
import { SwiperSlide, Swiper, SwiperRef } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { TextArea, TextField } from "../../components/atoms/TextField";
import { Controller } from "swiper";
import { Link, useHistory } from "react-router-dom";
import MutiFilesUpload from "../../components/molecules/MutiFilesUpload";
import { useSelector } from "react-redux";
import { ImagesData, ImagesStatus } from "store/slices/ImageSlice";
import { ArchiveData, ArchiveStatus } from "store/slices/ArchiveSlice";
import store from "store";
import { Product } from "models/product";
import { PostProduct } from "store/Thunks/ProductThunks";
import { ProductIdStatus } from "store/slices/productIdSlice";

SwiperCore.use([Controller]);

const AddProduct = (): JSX.Element => {
  const swiperRef = useRef<SwiperRef>(null);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState("");
  const navigate = useHistory();
  // const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  //
  // const handleExternalChangeSlide = (newSlideIndexToShow) => {
  //     swiperInstance.slidesTo(newSlideIndexToShow);
  // }
  const [isWaiting, setIsWaiting] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [salePrice, setSalePrice] = useState<number | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [images, setImages] = useState<Array<string>>([]);
  const [category, setCategory] = useState<number | undefined>();
  const [file, setFile] = useState<string>("");
  const isLoadingImage = useSelector(ImagesStatus);
  const imageDate = useSelector(ImagesData);
  const isLoadingArchive = useSelector(ArchiveStatus);
  const archiveData = useSelector(ArchiveData);
  const productIsLoading = useSelector(ProductIdStatus);
  const history = useHistory();

  const clickNext = () => {
    console.log("the price is : " + price);
    if (
      index == 0 &&
      (productTitle == "" || description == "" || shortDescription == "")
    ) {
      setError("You Have to complete all fields");
      return;
    }
    if (
      index == 1 &&
      (price == undefined ||
        salePrice == undefined ||
        quantity == undefined ||
        category == 0)
    ) {
      setError("You Have to complete all fields");
      return;
    }
    if (index == 2 && imageDate.result.length == 0) {
      setError("You Have to complete all fields");
      return;
    }
    if (index == 3 && archiveData.result.length == 0) {
      setError("You Have to complete all fields");
      return;
    }
    swiperRef.current?.swiper?.slideNext();
    setError("");
  };

  const clickIndex = (index: number) =>
    swiperRef.current?.swiper?.slideTo(index);

  useEffect(() => {
    // if (productIsLoading)
  }, []);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsWaiting(true);
  };

  const handleCate = (index: string) => setCategory(+index);

  const handleProductTitle = (e: string) => setProductTitle(e);

  const handleDescription = (e: string) => setDescription(e);

  const handleShortDescription = (e: string) => setShortDescription(e);

  const handleRegularPrice = (e: number | undefined) => {
    if (e != undefined) setPrice(e);
  };

  const handleQuantity = (e: number | undefined) => {
    if (e != undefined) setQuantity(e);
  };

  const handleSalePrice = (e: number | undefined) => {
    if (e != undefined) setSalePrice(e);
  };

  const handleFile = (e: string) => setFile(e);

  const handleSubmitProduct = async () => {
    const args: Product = {
      name: productTitle,
      description: description,
      short_description: shortDescription,
      regular_price: price,
      sale_price: salePrice,
      downloadable: true,
      download_url: archiveData.result,
      categories: category !== undefined ? [category] : [],
      stock_quantity: quantity,
      images: imageDate.result !== undefined ? imageDate.result : [],
      status: "publish",
      // tags: productTag
    };

    store.dispatch(PostProduct(args));
    // while (productIsLoading == true);
    history.push("/product");
  };

  const handleImages = (e: Array<string>) => {
    console.log("the values from images is : " + JSON.stringify(e));
    setImages(e);
  };

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className={"container"}></section>

        <section className="single-project-section">
          <div className="container">
            <div className="card border-0">
              <div className="card-body p-4">
                <h5 className="card-title">Add New Product</h5>
                <hr />
                <div className="p-4">
                  {error !== "" && (
                    <div className="bg-light p-4">
                      <h4 className="text_error">{error}</h4>
                    </div>
                  )}
                </div>
                <Swiper
                  ref={swiperRef}
                  // install Swiper modules
                  allowTouchMove={false}
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  // onSwiper={setSwiperInstance}
                  onSlideChange={(swiper) => setIndex(swiper.realIndex)}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log('slide change')}
                >
                  <SwiperSlide key={"001"}>
                    {/*<div className="form-body mt-4 w-50">*/}
                    {/*<form onSubmit={submitHandler}>*/}
                    {/*<MultiFileUpload/>*/}
                    <div className="mt-5 d-flex justify-content-center align-items-center">
                      <div className="w-75">
                        <div className="border-1 p-4 rounded">
                          <div className="mb-3 w-100">
                            <TextField
                              id={"producttitle"}
                              name={"producttitle"}
                              placeholder={"Product title"}
                              type={"text"}
                              className={"inputcheck"}
                              onChange={handleProductTitle}
                            />
                          </div>
                          <div className="mb-3">
                            <TextArea
                              id={"description"}
                              name={"description"}
                              placeholder={"Description"}
                              type={"text"}
                              row={3}
                              className={"inputcheck"}
                              onChange={handleDescription}
                            />
                            <TextArea
                              id={"shortdescription"}
                              name={"shortdescription"}
                              placeholder={"Short Description"}
                              type={"text"}
                              row={3}
                              className={"inputcheck"}
                              onChange={handleShortDescription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*</form>*/}
                  </SwiperSlide>
                  <SwiperSlide key={"002"}>
                    <div className="mt-5 d-flex justify-content-center align-items-center">
                      <div className="border-0 p-4 rounded">
                        <div className="row g-3">
                          <div className="col-md-6">
                            <TextField
                              id={"regularPrice"}
                              name={"regularPrice"}
                              placeholder={"Regular Price"}
                              type={"number"}
                              className={"inputcheck"}
                              onChange={handleRegularPrice}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              id={"salePrice"}
                              name={"salePrice"}
                              placeholder={"Sale Price"}
                              type={"number"}
                              className={"inputcheck"}
                              onChange={handleSalePrice}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              id={"quantity"}
                              name={"quantity"}
                              placeholder={"quantity"}
                              type={"number"}
                              className={"inputcheck"}
                              onChange={handleQuantity}
                            />
                          </div>
                          <CategoriesDropDownList catid={handleCate} />
                          <Link
                            to={"/category"}
                            className={"button-hyper hyperlink"}
                            // onClick={toggleModal}
                          >
                            Add New Category
                          </Link>
                          {/* <AddNewCategory /> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide key={"003"}>
                    <div className="mt-5">
                      <p>Please Upload images 399x429</p>
                      <MutiFilesUpload
                        key={"upload-images-01"}
                        title="Upload Images"
                        type={1}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide key={"004"}>
                    <div className="mt-5">
                      <p>Please Upload File downloadable</p>
                      <MutiFilesUpload
                        key={"upload-file-02"}
                        type={2}
                        title="Upload File"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide key={"005"}>
                    <div className="submit-container">
                      <button
                        className={"bd-dark text-white btn-normal"}
                        onClick={handleSubmitProduct}
                      >
                        {isWaiting == true ? (
                          <img
                            src={loadingimg}
                            alt={"loading-image"}
                            className="imageloop"
                          />
                        ) : (
                          "Publish Product"
                        )}
                      </button>
                      <p className={"text-black-50 mt-4 text-center"}>
                        By clicking on this button, you are adding a new
                        product,
                        <br />
                        Please check before you submit.
                      </p>
                    </div>
                    <div className="">
                      <Link to="/product" className="hyperlink">
                        Products List
                      </Link>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className={"mt-5 d-flex justify-content-between"}>
                  <div className={"progress-container"}>
                    {Array.from([0, 1, 2, 3, 4]).map((item, index) => (
                      <Link
                        key={"list-of-keys-" + index}
                        to={"#"}
                        onClick={() => clickIndex(item)}
                      >
                        <div
                          key={"pagination-" + item}
                          className={
                            "progress-item " + (index === item ? "active" : "")
                          }
                        ></div>
                      </Link>
                    ))}
                  </div>
                  <div>
                    {index != 4 && (
                      <Link
                        to={"#"}
                        className="theme-button"
                        onClick={clickNext}
                      >
                        <span>Next</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};
export default AddProduct;
