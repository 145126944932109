import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "components/sections/Header";
import CartItem from "./CartItem";
import { CartData } from "store/slices/CartSlice";
import Footer from "components/sections/Footer";
import store from "store";
import { addToCheckOut, checkoutNode } from "store/slices/CheckoutSlice";

const Cart = () => {
  const items = useSelector(CartData);
  const history = useHistory();

  const handleCheckOut = () => {
    // let names = items.data.map((e) => e.name);
    const obj: checkoutNode = {
      amount: items.data.reduce((e, i) => e + i.sale_price, 0),
      list_items: items.data.map((item) => {
        let value = {
          id: item.product_id,
          name: item.name,
          sale_price: item.sale_price,
        };
        return value;
      }),
      //   name: names.join(" / "),
    };
    store.dispatch(addToCheckOut(obj));
    history.push("/checkout");
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log("the value of token : " + token);
  //   console.log("the value is : " + items.access + ": and " + items.isLoading);
  //   if (items.access == false && items.isLoading == false)
  //     history.push("/login");
  //   // const carts =  localStorage.getItem("cart")
  //   // if (carts != null)
  //   //     setItems(JSON.parse(carts))
  //   // let result:number = 0
  //   // items?.map((item) => {
  //   //     result = +result + +(item.price != undefined ? item.price : 0)
  //   //     // return (item.price != undefined ? item.price : 0);
  //   // })
  //   // setTotalPrice(result)
  // }, [items.isLoading, items.data]);

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className="single-post-section">
          <div className="container-xxl">
            <div className="row">
              <div className="col-lg-8 bg-white p-5">
                <div className="post-content">
                  <div className="comments-box" id="comments">
                    {/*<h3>Cart</h3>*/}
                    <ul className="comments-list">
                      {items.isLoading == true ? (
                        <h1>loading</h1>
                      ) : (
                        items.data?.map((item, index) => {
                          return (
                            <CartItem
                              id={item?.cart_id}
                              name={item.name}
                              description={item.short_description}
                              price={item.sale_price}
                              index={index}
                              images={[
                                { src: item?.src, alt: "image for cart" },
                              ]}
                            />
                          );
                        })
                      )}
                      {/*<CartItem />*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-5">
                <div className="sidebar">
                  <div className="sidebar-widget widget_categories">
                    <h4>Cart</h4>
                    <p>Total items: {items?.count} </p>
                    <p>Total price: {items?.totalePrice} </p>
                    <Link
                      to="/checkout"
                      className="theme-button"
                      onClick={handleCheckOut}
                    >
                      <span>Check Out</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Cart;
