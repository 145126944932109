import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchProductByIdAsync } from "../../../store/Thunks/ProductThunks";
import React, { useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Image } from "../../../models/product";

type params = {
  id?: number;
  name: string;
  images: Array<Image>;
  short_description: string;
};

const TallProductCard: React.FC<params> = ({
  id,
  name,
  images,
  short_description,
}): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("the name is : "+ name + " and the short_description: "+ short_description)
  }, []);

  const handleDelete = (id?: number) => {
    if (id != undefined) dispatch(fetchProductByIdAsync(id));
  };

  return (
    <SwiperSlide>
      <NavLink
        to={id == -1 ? "/home" : "/product/" + id}
        className="item"
        onClick={() => handleDelete(id)}
      >
        <div className="scroller-post">
          <div className="image-holder">
            {images.map((item, index) => {
              if (index == 0)
                return (
                  <img
                    key={"productcart_" + item.id}
                    src={item.src}
                    alt={item.alt}
                  />
                );
            })}
          </div>
          <div className="hover-box">
            <p className="cat-link text-light">{name}</p>
            <h4 className={"text-white"}>
              {short_description.replace(/(<([^>]+)>)/gi, "").substring(0, 45)}
            </h4>
          </div>
        </div>
      </NavLink>
    </SwiperSlide>
  );
};

export default TallProductCard;
