import React from "react";
import { Link, useHistory } from "react-router-dom";
import { DeleteItemFromCartThunk } from "store/Thunks/CartThunks";
import loadingimg from "../../assets/images/loading-circal.png";
import { useSelector } from "react-redux";
import { CartStatus } from "store/slices/CartSlice";
import { Image } from "models/product";
import store from "store";

interface params {
  id?: number;
  name?: string;
  description?: string;
  price?: number;
  images?: Array<Image>;
  index: number;
}
const CartItem: React.FC<params> = ({
  id,
  name,
  description,
  price,
  images,
  index,
}) => {
  const history = useHistory();
  const isLoading = useSelector(CartStatus);
  const onDeleteHandler = (index: number) => {
    if (id != undefined) store.dispatch(DeleteItemFromCartThunk(id));
  };

  return (
    <li className="comment">
      <div className="comment-box">
        <div className="image-holder">
          {
            images !== undefined &&
              images.map((item, index) => {
                if (index == 0)
                  return (
                    <img
                      key={"productcart_" + index}
                      src={item.src}
                      alt={item.alt}
                    />
                  );
              })
            // (image && image.src && image.alt) ? <img src={image.src} alt={image.alt} /> : <img src="" alt={"isOnLoading"} />
          }
        </div>
        <div className="card-body">
          <h5 className="card-title">
            {" "}
            {name !== undefined && name.replace(/(<([^>]+)>)/gi, "")}
          </h5>
          <p className="card-text">
            {" "}
            {description &&
              description.replace(/(<([^>]+)>)/gi, "").substring(0, 45)}{" "}
          </p>
          <b className="card-text"> Total price: ${price} </b>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <Link
              to={{ pathname: "/cart" }}
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => onDeleteHandler(index)}
            >
              {isLoading ? (
                <img
                  src={loadingimg}
                  alt={"loading-image"}
                  className="imageloop"
                />
              ) : (
                "Remove"
              )}
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
