import accountIcon from "../../assets/images/Account.svg";
import Header from "../../components/sections/Header";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/sections/Footer";
import { TextField } from "../../components/atoms/TextField";
import { useSelector } from "react-redux";
import loadingimg from "../../assets/images/loading-circal.png";
import store from "../../store";
import { UserData, UserLoading } from "../../store/slices/UserSlice";
import { logOut, updateUser } from "store/Thunks/AuthThunks";
const Account = (): JSX.Element => {
  const customer = useSelector(UserData);
  const isLoadingCustomer = useSelector(UserLoading);
  const [mode, setMode] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [uname, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const saveMode = () => {
    setMode(!mode);
    if (mode == true) {
      let args = {
        username: uname,
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone_number: phoneNumber,
        id: customer.user.id,
      };
      store.dispatch(updateUser(args));
    }
  };

  const handleFirstname = (e: string) => {
    setFirstName(e);
  };
  const handleLastname = (e: string) => {
    setLastName(e);
  };
  const handleusername = (e: string) => {
    setUserName(e);
  };
  const handleemail = (e: string) => {
    setEmail(e);
  };

  const handlePassword = (e: string) => {
    setPassword(e);
  };

  const handlePhoneNumber = (e: string) => {
    setPhoneNumber(e);
  };

  const onLogOutHandler = () => {
    store.dispatch(logOut());
  };

  useEffect(() => {
    if (customer.user.first_name !== undefined)
      setFirstName(customer.user.first_name);
    if (customer.user.last_name !== undefined)
      setLastName(customer.user.last_name);
    if (customer.user.username !== undefined)
      setUserName(customer.user.username);
    if (customer.user.phone_number !== undefined)
      setPhoneNumber(customer.user.phone_number);
    if (customer.user.email !== undefined) setEmail(customer.user.email);
  }, [isLoadingCustomer]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader/>*/}
      <div id="container">
        <Header />
        <section className={"single-post-section account"}>
          <div className={"container-xxl"}>
            <div className={"head-account"}>
              <div className={"d-flex justify-content-between"}>
                <h2>Account</h2>
                <Link
                  to={"/login"}
                  className="theme-button"
                  onClick={onLogOutHandler}
                >
                  <span>Sign Out</span>
                </Link>
              </div>
              <div className={"hr"}></div>
            </div>

            <div className={"row"}>
              <div className={"col-4"}>
                <div className={"accountImage"}>
                  <img src={accountIcon} width={75} />
                </div>
                <div>
                  <h5>
                    {firstname} {lastname}
                  </h5>
                  <p>{email}</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <h4>
                        <a href={"#"} className={"active"}>
                          Personal information
                        </a>
                      </h4>
                    </li>
                    <li>
                      <h4>
                        <a href={"/orders"} className={""}>
                          Orders & Payments
                        </a>
                      </h4>
                    </li>
                    {/*<li>*/}
                    {/*    <h4><a href={"#"} className={""}>Update Login & Password</a></h4>*/}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>

              {isLoadingCustomer == false ? (
                <div className={"col-8"}>
                  <h3>Personla Information</h3>
                  <p>
                    Manage your personal inforamtion, including phone numbers
                    and email address where you can be contacted
                  </p>
                  {mode == false ? (
                    <div className={"flex-container"}>
                      <div className={"card-item"}>
                        <h5>First Name</h5>
                        <p>{customer.user.first_name}</p>
                      </div>
                      <div className={"card-item"}>
                        <h5>Last Name</h5>
                        <p>{lastname}</p>
                      </div>
                      <div className={"card-item"}>
                        <h5>User name</h5>
                        <p>{uname}</p>
                      </div>
                      <div className={"card-item"}>
                        <h5>Phone number</h5>
                        <p>{phoneNumber}</p>
                      </div>
                      <div className={"card-item"}>
                        <h5>Email Address</h5>
                        <p>{email}</p>
                      </div>
                      <div className={"card-item"}>
                        <h5>Role</h5>
                        <p>
                          {customer.user.is_admin == 1 ? "Admin" : "Customer"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={"flex-container"}>
                      <div className={"card-item"}>
                        <TextField
                          id={"firstname"}
                          name={"firstname"}
                          placeholder={"First name"}
                          type={"text"}
                          value={firstname}
                          onChange={handleFirstname}
                          className={"inputcheck"}
                        />
                      </div>
                      <div className={"card-item"}>
                        <TextField
                          id={"lastname"}
                          name={"lastname"}
                          placeholder={"Last name"}
                          type={"text"}
                          value={lastname}
                          onChange={handleLastname}
                          className={"inputcheck"}
                        />
                      </div>
                      <div className={"card-item"}>
                        <TextField
                          id={"username"}
                          name={"username"}
                          placeholder={"User Name"}
                          type={"text"}
                          value={uname}
                          onChange={handleusername}
                          className={"inputcheck"}
                        />
                      </div>
                      <div className={"card-item"}>
                        <TextField
                          id={"phonenumber"}
                          name={"phoneNumber"}
                          placeholder={"Phone number"}
                          type={"phone"}
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                          className={"inputcheck"}
                        />
                      </div>
                      <div className={"card-item"}>
                        <TextField
                          id={"email"}
                          name={"email"}
                          placeholder={"E-mail"}
                          type={"text"}
                          value={email}
                          onChange={handleemail}
                          className={"inputcheck"}
                        />
                      </div>
                      <div className={"card-item"}>
                        <TextField
                          id={"password"}
                          name={"password"}
                          placeholder={"Password"}
                          type={"text"}
                          value={email}
                          onChange={handlePassword}
                          className={"inputcheck"}
                        />
                      </div>
                    </div>
                  )}
                  <div className={"d-flex justify-content-around"}>
                    <div></div>
                    <a className={"hyperlink my-4"} onClick={saveMode}>
                      {mode == false ? "Update" : "Save"}
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  className={
                    "col-8 d-flex justify-content-center align-items-center"
                  }
                >
                  <img
                    src={loadingimg}
                    alt={"loading-image"}
                    className="imageloop"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Account;
