import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchProductByIdAsync } from "../../../store/Thunks/ProductThunks";
import store from "../../../store";
import { Image, Product } from "../../../models/product";
import { fetchReviewThunk } from "store/Thunks/ReviewThunks";

type params = {
  id?: number;
  name: string;
  short_description: string;
  images: Array<Image>;
};

const ProductCard: React.FC<params> = ({
  id,
  name,
  short_description,
  images,
}): JSX.Element => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  const fetchProductId = (id?: number) => {
    if (id != undefined) {
      dispatch(fetchProductByIdAsync(id));
      dispatch(fetchReviewThunk(id));
      console.log("you click in fetchProductId");
    }
  };

  useEffect(() => {}, []);
  return (
    <Link
      to={id == -1 ? "/home" : "/product/" + id}
      className={"portfolio-post aa " + filter}
      onClick={() => fetchProductId(id)}
    >
      <figure className="effect-milo hover2">
        {images?.map((item, index) => {
          if (index == 0)
            return (
              <img
                key={"productcart_" + index}
                src={item.src}
                alt={item.alt}
                className={"image-related"}
              />
            );
        })}
        <figcaption>
          <p>
            {short_description?.replace(/(<([^>]+)>)/gi, "").substring(0, 45)}
            ...
          </p>
          <h4 className={"text-white"}>{name}</h4>
        </figcaption>
      </figure>
    </Link>
  );
};

export default ProductCard;
