import { Product } from "../../models/product";
import ProductCard from "../molecules/ProductCard";
import Loading from "../../assets/images/gif/product_loading.gif";
import React, { useCallback, useEffect, useState } from "react";
import "swiper/swiper.min.css";
import loadingimg from "../../assets/images/loading-circal.png";
import CategoriesDropDownList from "../molecules/CategoriesDropDownList";
import { TextField } from "../atoms/TextField";
import { useParams } from "react-router-dom";
import store from "../../store";
import { fetchProductsAsync } from "../../store/Thunks/ProductThunks";

type params = {
  products: Product[];
  isLoadingProducts: Boolean;
};

type paramsSearch = {
  search: string;
};
const FilterResult: React.FC<params> = ({
  products,
  isLoadingProducts,
}): JSX.Element => {
  const [filterIndex, setFilterIndex] = useState(0);
  const [category, setCategory] = useState<number>();
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const params: paramsSearch = useParams();

  const handleCate = (index: string) => {
    setCategory(+index);
    console.log("the cate id is : " + category);
  };

  const handleMinPrice = (e: number) => {
    setMinPrice(e);
  };

  const handleMaxPrice = (e: number) => {
    setMaxPrice(e);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(
      fetchProductsAsync({
        category: category,
        search: params.search,
        min_price: minPrice,
        max_price: maxPrice,
      })
    );
  };

  useEffect(() => {
    // if (params != undefined)
    // store.dispatch(fetchSearchProductsAsync())
  }, [category, minPrice, maxPrice, params.search, isLoadingProducts]);

  return (
    <>
      <section className="filter-section container">
        {/*<Swiper className="filter ul"*/}
        {/*        spaceBetween={5}*/}
        {/*        slidesPerView={8}>*/}
        {/*    <SwiperSlide className={"li"}><a className={(filterIndex == 0) ? "active" : ""} href="#" data-filter="*" onClick={()=>setFilterIndex(-1)}>Show all</a></SwiperSlide>*/}
        {/*    {*/}
        {/*        (isLoadingTags == false && isLoadingProducts == false) && tags.map((item, index) => <SwiperSlide className={"li"}><a href="#" className={(filterIndex == index + 1) ? "active" : ""} data-filter={`.${item.name}`} onClick={()=>setFilterIndex(index + 1)}>{item.name}</a></SwiperSlide>)*/}
        {/*    }*/}
        {/*    /!*<SwiperSlide></SwiperSlide>*!/*/}
        {/*</Swiper>*/}

        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <CategoriesDropDownList catid={handleCate} type={2} />
            <div className="col-md-5">
              <TextField
                id={"regularPrice"}
                name={"regularPrice"}
                placeholder={"Min Price"}
                type={"number"}
                className={"inputcheck"}
                onChange={handleMinPrice}
                isReuired={false}
              />
            </div>
            <div className="col-md-5">
              <TextField
                id={"salePrice"}
                name={"salePrice"}
                placeholder={"Max Price"}
                type={"number"}
                className={"inputcheck"}
                onChange={handleMaxPrice}
                isReuired={false}
              />
            </div>
            <div className={"col-md-2 d-flex flex-wrap align-content-center"}>
              <button type={"submit"} className={"btn_primary"}>
                Filter
              </button>
            </div>
          </div>
        </form>

        {/*<ul className="filter">*/}
        {/*    <li><a className="active" href="#" data-filter="*">Show all</a></li>*/}
        {/*    {*/}
        {/*        (isLoadingTags == false && isLoadingProducts == false) && tags.map((item) => <li><a href="#" data-filter={`.${item.name}`} onClick={()=>{}}>{item.name}</a></li>)*/}
        {/*    }*/}
        {/*</ul>*/}
      </section>
      <section className="portfolio-section h-auto">
        <div className="container">
          <div>
            {isLoadingProducts == false && (
              <p>About ({products.length}) results </p>
            )}
          </div>
          <div className="portfolio-box iso-call">
            {isLoadingProducts == true ? (
              <div
                className={
                  "d-flex justify-content-center align-items-center loading-center"
                }
              >
                <img src={loadingimg} className="imageloop" />
              </div>
            ) : (
              products.map((item, index) => {
                return (
                  <ProductCard
                    key={"searchProduct_" + item.id}
                    id={item.id}
                    name={item.name}
                    short_description={item.short_description}
                    images={item.images}
                  />
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FilterResult;
