import { TextField } from "../../components/atoms/TextField";
import logo from "../../assets/images/logo-image.svg";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserError, UserLoading } from "../../store/slices/UserSlice";
import store from "../../store";
import Footer from "../../components/sections/Footer";
import { Link } from "react-router-dom";
import loadingimg from "../../assets/images/loading-circal.png";
import { addNewUser } from "store/Thunks/AuthThunks";

const SignUp = (): JSX.Element => {
  const history = useHistory();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [uname, setUname] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [errorString, setError] = useState("");
  // let isLoading:boolean = useSelector(UserLoading)
  // let isLogin:boolean = useSelector(UserLogin)
  let isLoading = useSelector(UserLoading);
  let error: string = useSelector(UserError);
  const [isWaiting, setIsWaiting] = useState(false);
  // const dispatch = useDispatch()

  const handleFname = (e: any) => {
    setFname(e);
  };
  const handleLname = (e: any) => {
    setLname(e);
  };
  const handleUname = (e: any) => {
    setUname(e);
  };
  const handlePhone = (e: any) => {
    setPhone(e);
  };
  const handleEmail = (e: any) => {
    setEmail(e);
  };
  const handlePassword = (e: any) => {
    setPassword(e);
  };
  const handlecPassword = (e: any) => {
    setcPassword(e);
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== cpassword) setError("Please confirm your password!");
    store.dispatch(
      addNewUser({
        email: email,
        first_name: fname,
        last_name: lname,
        username: uname,
        phone_number: phone,
        password: password,
      })
    );
    setIsWaiting(true);
    // while (isLogin != true);
    console.log("you enter to submit handler : " + isLoading);
    // while (state.isLogin == true);
  };

  useEffect(() => {
    if (error === "" && isLoading === false && isWaiting === true) {
      // console.log("the user is login")
      history.push("/welcome");
    }
    // console.log("the state of is Login is : " + isLogin)
    // store.dispatch(LogOut())
  }, [isLoading]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="">
          <section className="mb-5">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {error !== "" && (
                <div className={"bg-danger text-center"}>
                  <h5 className={"text-white py-3"}>{error}</h5>
                </div>
              )}
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    <form id="contact-form" onSubmit={onSubmitHandler}>
                      <h3> Register </h3>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className={"row mb-4"}>
                            <div className={"col-lg-6"}>
                              <TextField
                                id={"firstname"}
                                name={"firstname"}
                                placeholder={"First name"}
                                type={"text"}
                                onChange={handleFname}
                              />
                            </div>
                            <div className={"col-lg-6"}>
                              <TextField
                                id={"lastname"}
                                name={"lastname"}
                                placeholder={"Last name"}
                                type={"text"}
                                onChange={handleLname}
                              />
                            </div>
                          </div>

                          <TextField
                            id={"username"}
                            name={"username"}
                            placeholder={"User name"}
                            type={"text"}
                            onChange={handleUname}
                          />
                          <TextField
                            id={"phone"}
                            name={"phone"}
                            placeholder={"Phone number"}
                            type={"phone"}
                            onChange={handlePhone}
                          />
                          <TextField
                            id={"email"}
                            name={"email"}
                            placeholder={"Email address"}
                            type={"email"}
                            onChange={handleEmail}
                          />

                          <TextField
                            id={"password"}
                            name={"password"}
                            placeholder={"Password"}
                            type={"password"}
                            onChange={handlePassword}
                          />
                          <TextField
                            id={"cpassword"}
                            name={"cpassword"}
                            placeholder={"Confirm Password"}
                            type={"password"}
                            onChange={handlecPassword}
                          />

                          <Link to={"/login"} className={"hyperlink"}>
                            I already Have an Account
                          </Link>
                          <br />
                          <br />
                          <br />
                          <button type="submit" id="submit_contact">
                            {isWaiting == true ? (
                              <img
                                src={loadingimg}
                                alt={"loading-image"}
                                className="imageloop"
                              />
                            ) : (
                              "Sign Up"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default SignUp;
