import logo from "../../assets/images/logo-image.svg";
import { TextField } from "../../components/atoms/TextField";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/sections/Footer";
import { useEffect, useState } from "react";
import { axiosApi } from "../../services/api";
import loadingimg from "../../assets/images/loading-circal.png";

const ForgetPassword = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const history = useHistory();

  const handleEmail = (e: string) => {
    setEmail(e);
  };
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = axiosApi.post("bdpwr/v1/reset-password", { email: email });
    setIsWaiting(true);
    // store.dispatch(fetchUser({ username: uname, password: password }))
    // while (isLogin != true);
    // console.log("you enter to submit handler : " + isLogin)
    // while (state.isLogin == true);
  };

  useEffect(() => {
    if (isWaiting == true) {
      // console.log("the val ")
      history.push("/set-password/" + email);
    }
  }, [isWaiting]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="content">
          <section className="contact-info-section">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    <form id="contact-form" onSubmit={onSubmitHandler}>
                      {/*<h3> Login </h3>*/}
                      <div className="row d-flex justify-content-center ">
                        <h6>insert your email</h6>
                        <div className="col-md-6">
                          <TextField
                            id={"email"}
                            name={"email"}
                            placeholder={"Email"}
                            type={"email"}
                            onChange={handleEmail}
                          />
                          <br />
                          <br />
                          <br />
                          <button type="submit" id="submit_contact">
                            {isWaiting == true ? (
                              <img
                                src={loadingimg}
                                alt={"loading-image"}
                                className="imageloop"
                              />
                            ) : (
                              "Send Email"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default ForgetPassword;
