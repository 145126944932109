import React from "react";
import { Product } from "../../../models/product";
import { useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { fetchProductsAsync } from "../../../store/Thunks/ProductThunks";
import { Category } from "../../../models/Category";
import store from "../../../store";
import { SwiperSlide } from "swiper/react";

type param = {
  obj: Category;
  index: number;
};

const CategoryItem: React.FC<param> = ({ obj, index }): JSX.Element => {
  return (
    <SwiperSlide>
      <Link
        to={"/search"}
        className="a scroller-post"
        onClick={() =>
          store.dispatch(fetchProductsAsync({ category: obj.category_id }))
        }
      >
        {index % 2 == 0 && (
          <div className="cont-holder">
            <div className="text-holder">
              <h4 className={"p-3"}>{obj.name}</h4>
            </div>
            {/*<Image src={require("../../assets/images/logo-image.png")} alt={"portfolio2"}/>*/}
            {obj.src && <img src={obj.src} alt={"category"} />}
          </div>
        )}
        <div className="num-holder">
          <span>{index <= 10 ? "0" + (index + 1) : index + 1}</span>
        </div>
        {index % 2 != 0 && (
          <div className="cont-holder">
            <div className="text-holder">
              <h4 className={"p-3"}>{obj.name}</h4>
            </div>
            {/*<Image src={require("../../assets/images/logo-image.png")} alt={"portfolio2"}/>*/}
            {obj.src && <img src={obj.src} alt={"category"} />}
          </div>
        )}
      </Link>
    </SwiperSlide>
  );
};

export default CategoryItem;
