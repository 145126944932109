import {useEffect, useState} from "react";
import {LineItem} from "../../../models/Order";

interface Para {
    orderId?: number
    customer?: string
    date: string
    price?: number
    status: string
    line_item?: string
    src?: string

}

const OrderItem:React.FC<Para> = ({
                                      orderId,
                                      customer,
                                      date,
                                      price,
                                      status,
                                      line_item,
                                      src
                                  }): JSX.Element =>{

    const [sColor, setsColor] = useState("");
    const [data, setDate] = useState("")

    useEffect(()=>{
        const format = new Date(date)
        let d =  format.toLocaleString()//dateFormat(format, "yy-mm-dd h:MM:ss TT");
        setDate(d)
        statusColor(status)
    },[sColor])
    const statusColor = (text:string) =>{
        if (text === "processing")
            setsColor("text-dark");
        else if (text === "pending payment")
            setsColor("text-info");
        else if (text === "completed")
            setsColor("text-primary");
        else if (text === "cancelled")
            setsColor("text-warning");
    };

    return (
        <tr>
            <td><img width={100} height={100}  src={src} alt="order image"/></td>
            <th scope="row"><h6>{orderId}</h6></th>
            <td><p> {customer} </p></td>
            {(line_item !== undefined) ? <td><p> {line_item}, </p></td>:
                <td><p> Product </p></td>
            }
            <td><p> {date} </p></td>
            <td><p> {price} </p></td>
            <td ><p className={sColor}> {status} </p></td>
        </tr>
    )
}

export default OrderItem