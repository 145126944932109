import { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

type params = {
  isLoading?: boolean;
};
const Footer: React.FC<params> = ({ isLoading }): JSX.Element => {
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    // if (isLoading == false){
    const scriptElement = document.querySelector(".script1");
    if (scriptElement && scriptElement.parentNode) {
      scriptElement.parentNode.removeChild(scriptElement);
    }
    // }
    // // if (isLoading == true)
    // //     setIsLoading(true)
    // setIsLoading(false)
    // // if (isLoading == true){
    if (isLoading == false) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
    // }
    // setTimeout(() => {
    //     setIsLoading(false);
    // }, 1100)
  }, [isLoading]);
  return (
    <footer className="template-footer h-auto">
      {(window.location.pathname == "/" ||
        window.location.pathname == "/home") && (
        <Link className="go-top" to={""} onClick={() => window.scrollTo(0, 0)}>
          <i className="far fa-arrow-alt-circle-up"></i>
        </Link>
      )}
      <div className="instagram-line">
        <div className="container">
          <ul className="insta-list">
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="src/costumer/components#">
                <img
                  src={require("../../assets/images/logo-image.png")}
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="widget-part-area">
        <div className="footer-widget-line">
          <div className="image-widget">
            <a href="index.html">
              <img src="./assets/images/footer-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div className="footer-widget-line">
          <h5>Locations</h5>
          <ul className="custom-list">
            <li>
              <a href="#">Rabat</a>
            </li>
            <li>
              <a href="#">Paris</a>
            </li>
            <li>
              <a href="#">New York</a>
            </li>
          </ul>
        </div>
        <div className="footer-widget-line">
          <h5>Connect</h5>
          <ul className="custom-list">
            <li>
              <a href="#">instagram</a>
            </li>
            <li>
              <a href="#">facebook</a>
            </li>
          </ul>
        </div>
        <div className="footer-widget-line">
          <h5>Get in Touch</h5>
          <ul className="custom-list">
            <li>
              <a href="#">info@bysabprint.com</a>
            </li>
            <li>
              <a href="#">+212 89 00 92 01</a>
            </li>
          </ul>
        </div>
      </div>
      {isLoading !== undefined && (
        <>
          <Helmet>
            {/*{(loading == true) && (<script async className="script1" src="../assets/js/plugins/Dras-And-Drop/dist/imageuploadify.min"></script>)}*/}
            {/*{(loading == true) && (<script async className="script1" src="../assets/js/script.js"></script>)}*/}
          </Helmet>
          {/*{(loading == false) && <script async className="script1" src="../assets/js/script.js"></script>}*/}
        </>
      )}
    </footer>
  );
};

export default Footer;
