import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineCheck } from "react-icons/all";
import loadingimg from "../../../assets/images/loading-circal.png";
import zipIcon from "../../../assets/images/zip.svg";
import store from "store";
import { addImageThunk } from "store/Thunks/ImageThunks";
import { addArchiveThunk } from "store/Thunks/ArchiveThunks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImagesData, ImagesStatus } from "store/slices/ImageSlice";

type Params = {
  filesrc: Array<any>;
  type: number;
  value?: Array<File>;
  onDelete: (src?: any) => void;
};

type File = {
  fileName: string;
  fileContents: string;
};

type Image = {
  id: number;
  src: string;
};

const ShowFiles: React.FC<Params> = ({
  filesrc,
  type,
  value,
  onDelete,
}): JSX.Element => {
  const [file, setFile] = useState<string | undefined>(undefined);
  const [filebase64, setFileBase64] = useState<File | undefined>(undefined);
  const [paths, setPaths] = useState<Array<string>>([]);
  const [src, setSrc] = useState<string>("");
  const isLoading = useSelector(ImagesStatus);
  const [isSend, setIsSend] = useState(false);
  const [images, setImages] = useState<Array<Image>>([]);
  const [selectedImage, setSelectedImage] = useState<Array<string>>([]);

  // const readFileAsDataURL = (file: Blob): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onloadend = () => resolve(reader.result as string);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(file);
  //   });
  // };

  useEffect(() => {
    // setSelectedImage([]);
    // console.log("\n\n");
    // filesrc.map((e) => {
    //   console.log(
    //     "the name of file src is : " +
    //       e.name +
    //       " and the lenght: " +
    //       filesrc.length
    //   );
    // });
    // selectedImage.map((e) => {
    //   console.log("the path is : " + e);
    // });
  }, [filesrc]);

  // const onDeleteX = (ev: string) => {
  //   setSelectedImage((current) => current.filter((e) => e != ev));
  // };

  return (
    <div className={"image-upload-container"}>
      {(filesrc.length > 0 || 1 == 1) && (type == 1 || type == 3)
        ? filesrc.map((item, index) => (
            <div
              className={"image-upload"}
              key={index + "-image_" + Math.random() * 100 + type}
            >
              <img
                // src={value == undefined ? item : images[index].src}
                src={item.url}
                alt={"img is null"}
                className={""}
              />
              <button
                className={"delete-image"}
                onClick={() => onDelete(index)}
              >
                x
              </button>
              {
                // isLoading !== undefined &&
                //   (isLoading === false && isSend === true ? (
                //     <AiOutlineCheck className={"check-upload"}></AiOutlineCheck>
                //   ) : (
                //     isSend === true ?? (
                //       <div className={"check-upload loading-center"}>
                //         <img src={loadingimg} className="imageloop" />
                //       </div>
                //     )
                //   ))
              }
            </div>
          ))
        : type == 2 &&
          filesrc.length > 0 && (
            <div className={"image-upload"} key={"file_" + type}>
              <img src={zipIcon} alt={"img is null"} className={""} />
              <button className={"delete-image"} onClick={() => onDelete()}>
                x
              </button>
            </div>
          )}
    </div>
  );
};

export default ShowFiles;
