import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../molecules/ProductCard";
import Loading from "../../assets/images/gif/product_loading.gif";
import React, { useEffect, useState } from "react";
import { ProductsData, ProductsStatus } from "../../store/slices/ProductsSlice";
import { Product } from "../../models/product";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import store from "../../store";
import { fetchProductsAsync } from "../../store/Thunks/ProductThunks";

type params = {
  products: Array<Product>;
  isLoading: boolean;
};
const SixProducts: React.FC<params> = ({
  products,
  isLoading,
}): JSX.Element => {
  // const products = useSelector(ProductsRecentData)
  // const loading = useSelector(ProductsRecentStatus)

  useEffect(() => {}, [isLoading]);

  // const dispach = useDispatch()
  return (
    <section className="portfolio-section">
      <div className="container-xxl">
        <h2 className="pb-3">Recent Collections</h2>
        <div className="portfolio-box iso-call">
          {isLoading == true
            ? [...Array(9)].map((e, i) => {
                return (
                  <ProductCard
                    id={-1}
                    key={"loadingProduct_" + i * 12}
                    name={""}
                    short_description={""}
                    images={[
                      {
                        src: Loading,
                        alt: "loading product image",
                      },
                    ]}
                  />
                );
              })
            : products.map((item, index) => {
                if (index <= 5)
                  return (
                    <ProductCard
                      key={"sixProduct_" + item.id}
                      id={item.id}
                      name={item.name}
                      short_description={item.short_description}
                      images={item.images}
                    />
                  );
              })}
          <Link
            to={"/collection/"}
            // onClick={()=>store.dispatch(fetchSearchProductsAsync({}))}
            className="active button-one d-flex justify-content-center my-5"
          >
            <span>Show more</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SixProducts;
