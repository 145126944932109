import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { access } from "fs"
import { RootState } from "store"
import { checkoutThunk } from "store/Thunks/CheckoutThunk"
import { checkoutModel } from "store/intialState"


export type checkoutNode = {
    user_id?: number,
    customer_id?: string,
    card_id?: string,
    first_name?: string,
    last_name?: string,
    email?: string,
    phone_number?: string,
    country?: string,
    city?: string,
    street?: string,
    post_code?: string,
    amount?: number,
    name?: string,
    list_items?: Array<{
        id: number,
        name:string,
        sale_price: number
    }>,
    token?: string,
}


type checkoutState = {
    data: checkoutNode,
    error: string,
    isLoading: boolean,
    success: boolean,
}

const intialCheckOutState: any = {
    data:{},
    error: "",
    success: false,
    isLoading: true,
}

const checkoutSlice = createSlice({
    name: "categories",
    initialState: intialCheckOutState,
    reducers: {
        addToCheckOut(state, action: PayloadAction<checkoutNode>)
        {
            console.log("you press to add to checkout")
            state.data = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkoutThunk.pending, (state: checkoutState) => {
                state.isLoading = true
            })
            .addCase(checkoutThunk.fulfilled, (state: checkoutState, action: PayloadAction<any>) => {
                state.isLoading = false
                if (action.payload.status == "succeeded")
                    {
                        state.success = true;
                    }
            })
            .addCase(checkoutThunk.rejected, (state: checkoutState, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = "An error occurred during the payment process"
            }) 
    }
})

  

export const CheckoutStatus = (state: RootState): boolean =>
    state.checkout.isLoading

export const CheckoutData = (state: RootState): checkoutNode =>
    state.checkout.data
export const checkoutSuccess = (state: RootState): boolean => 
    state.checkout.success

export const CheckoutError = (state: RootState): string =>
    state.checkout.error
export const { addToCheckOut } =
    checkoutSlice.actions
export default checkoutSlice.reducer