import { Image } from "../../../models/product";
import React, { useState } from "react";
import { ImagesList } from "../../../components/atoms/ImagesList";

interface params {
  images: Image[];
}

const ImagesProduct: React.FC<params> = ({ images }): JSX.Element => {
  const [imageId, setImageId] = useState(0);

  const handleIndex = (data: number) => {
    setImageId(data);
  };

  return (
    <div className="col-md-6">
      <div className="feature-image">
        <img src={images[imageId].src} alt={"product image"} />
        {/*<img src={require("../../assets/images/upload/carousel-item-3.png")} alt="project-img" />*/}
      </div>
      <ImagesList images={images} onData={handleIndex} />
    </div>
  );
};

export default ImagesProduct;
