import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosNew, wooApi} from "../../services/api";
import { Categories, Category } from "models/Category";
import { ServerError } from "models/error";

export const fetchCategoriesAsync = createAsyncThunk<any, void, {
    rejectValue:ServerError
}>(
    'categories/fetchCategories',
    async (_, {rejectWithValue}): Promise<any> => {
        try{
            const { data } = await axiosNew.get("/categories")
            return data
        }
        catch(error: any)
        {
            rejectWithValue({message: error.message, status: error.status})
            return error.message
        }
    }
)

export const addCategoryAsync = createAsyncThunk(
    'categories/addCategory',
    async (obj?: Partial<Category>): Promise<Category> => {
        try{
            let value:Category = {
                category_id : -1,
                name :"",
                src: ""
            }
            const { data } = await axiosNew.post("/categories", {
                data: obj
            })

            if (obj !== undefined && obj?.name !=undefined && obj.src != undefined)
            {
                value = {
                    category_id: data.result,
                    name: obj.name,
                    src: obj.src
                }
            }
            return value
        }
        catch(error)
        {
            return error.message
        }
    }
)

export const deleteCategoryAsync = createAsyncThunk(
    'categories/deleteCategory',
    async (id?: Partial<number>): Promise<any> => {
        try{
            const { data } = await axiosNew.delete(`/categories/${id}`)
            console.log("the value is :"+ JSON.stringify(data))
            return data
        }
        catch(error)
        {
            return error.message
        }
    }
)

export const editCategoryAsync = createAsyncThunk(
    'categories/editCategory',
    async (id?: Partial<number>, obj?: Partial<any>): Promise<any> => {
        try{
            const { data } = await axiosNew.put(`/categories/${id}`, {
                data : obj
            })
            return data
        }
        catch(error)
        {
            return error.message
        }
    }
)