import { useForceUpdate } from "framer-motion/types/utils/use-force-update";
import Footer from "../components/sections/Footer";
import { useEffect, useState } from "react";
import { TokenModel } from "../models/user";
import Header from "../components/sections/Header";
import { motion } from "framer-motion";
import styled from "styled-components/macro";

const Error = (): JSX.Element => {
  useEffect(() => {}, []);

  const Heading = styled(motion.h1)`
    font-size: 10vw;
  `;

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className="single-project-section">
          <div className="container d-flex justify-content-center flex-column align-items-center">
            <h1 className={"h-100"}>Error Establishing Connection</h1>
            <br />
            <br />
            <br />
            <br />
            <Heading>502</Heading>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};
export default Error;
