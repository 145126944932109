import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {intialProductsState} from "../intialState";
import {Product, Products} from "../../models/product";
import {RootState} from "../index";
import {deleteProductsAsync, fetchProductsAsync, PostProduct} from "../Thunks/ProductThunks";

export const ProductsSlice = createSlice({
    name: "products",
    initialState: intialProductsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsAsync.pending, (state: Products) => {
                state.isLoading = true
            })
            .addCase(fetchProductsAsync.fulfilled, (state: Products, action: PayloadAction<Array<Product>>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchProductsAsync.rejected, (state: Products, action:PayloadAction<any>) => {
                console.log("you have an error in slice products")
                state.isLoading = false
                state.error = action.payload.message
            })
            .addCase(PostProduct.pending, (state: Products) => {
            state.isLoading = true
        })
            .addCase(PostProduct.fulfilled, (state: Products, action: PayloadAction <Product>) => {
                state.isLoading = false
                state.data.push(action.payload)
            })
            .addCase(PostProduct.rejected, (state: Products) => {
                state.isLoading = false
                state.error = "the error : rejected"
            })
            .addCase(deleteProductsAsync.pending, (state: Products) => {
                state.isLoading = true
            })
            .addCase(deleteProductsAsync.fulfilled, (state: Products, action: PayloadAction<number>) => {
                state.isLoading = false
                state.data = state.data.filter(item => item.id != action.payload)
            })
            .addCase(deleteProductsAsync.rejected, (state: Products, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })
    }
})

export const ProductsStatus = (state: RootState): boolean =>
    state.products.isLoading
export const ProductsData = (state: RootState): Array<Product> =>
    state.products.data

export const ProductsError = (state: RootState): string =>
    state.products.error
export default ProductsSlice.reducer