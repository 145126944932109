import CategoryItem from "../../../components/molecules/CategoryItem";
import Swiper from "swiper";
import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import loading from "../../../assets/images/gif/product_loading.gif";
import { Category } from "../../../models/Category";

type params = {
  categories: Array<Category>;
  isLoading: boolean;
};

const Categories: React.FC<params> = ({
  categories,
  isLoading,
}): JSX.Element => {
  // const categories = useSelector(CategoriesData);
  // const isLoading = useSelector(CategoriesStatus);
  const intervalref = useRef<number | null>(null);
  const [swiper, setSwiper] = useState<Swiper | undefined>(undefined);

  const startInterval = () => {
    if (intervalref.current !== null) return;
    intervalref.current = window.setInterval(() => {
      swiper?.slideNext();
    }, 2000);
  };

  const initSwiper = () => {
    const mySwiper = new Swiper(".swiper-container2", {
      slidesPerView: 4,
      spaceBetween: 0,
      loop: true,
      centeredSlides: true,
      // autoplay: {
      //     delay: 2500,
      //     disableOnInteraction: true,
      // },
      // navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      // },
      breakpoints: {
        // Responsive breakpoints
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
    setSwiper(mySwiper);
  };

  useEffect(() => {
    // console.log("categories : " + JSON.stringify(categories))
    initSwiper();
    if (swiper !== undefined) {
      startInterval();
    }
    // mySwiper.slideNext();
    return () => {
      if (intervalref.current !== null) {
        window.clearInterval(intervalref.current);
      }
    };
  }, [isLoading]);

  return (
    // <section className="scroller-section3 scroller-section4 container-fluid h-auto ">
    <section className={"scroller-section4 container-xxl"}>
      {/*<h2>Categories</h2>*/}
      <h2 className="pb-5">Categories</h2>
      <div className="scroller-box owl-scroller swiper-container2 d-flex justify-content-center flex-column">
        <div className="wl-carousel owl-theme swiper-wrapper">
          {isLoading
            ? [...Array(6)].map((e, i) => (
                <CategoryItem
                  key={"cate-00" + i * 2}
                  obj={{ category_id: -1, name: "", src: loading }}
                  index={i}
                />
              ))
            : categories !== undefined &&
              categories.map((e, i) => {
                if (e.name !== "Uncategorized" && i < 8)
                  return (
                    <CategoryItem
                      key={"cate-00" + e.category_id}
                      obj={e}
                      index={i}
                    />
                  );
              })}
        </div>
      </div>
    </section>
  );
};

export default Categories;
