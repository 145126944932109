import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import { ProductsData, ProductsStatus } from "../../store/slices/ProductsSlice";
import SearchForm from "../../components/molecules/SearchForm";
// import {TagsData, TagsStatus} from "../store/slices/TagsSlice";
import FilterResult from "../../components/sections/FilterResult";
import { useParams } from "react-router-dom";

type paramsSearch = {
  search: string;
};
const ShowResult = ({}): JSX.Element => {
  const products = useSelector(ProductsData);
  const isLoading = useSelector(ProductsStatus);
  // const tagsData = useSelector(TagsData);
  // const loadingTags = useSelector(TagsStatus);
  const params: paramsSearch = useParams();
  // const [tags, setTags] = useState<Tag[]>([]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <Header />
        <div id="content">
          <section className="page-banner-section">
            <SearchForm value={params !== undefined ? params.search : ""} />
            {/*<div className="container">*/}
            {/*    <h1>Portfolio</h1>*/}
            {/*    <p>The modern architect with innovation</p>*/}
            {/*</div>*/}
            {/*<div className="info-box">*/}

            {/*</div>*/}
          </section>
          <FilterResult products={products} isLoadingProducts={isLoading} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ShowResult;
