import Footer from "components/sections/Footer";
import Header from "components/sections/Header";
import SixProducts from "components/sections/SixProducts";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductsData, ProductsStatus } from "store/slices/ProductsSlice";
import ProductTable from "./Components/ProductTable";

const Product = (): JSX.Element => {
  const [error, setError] = useState("");

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className={"container"}></section>

        <section className="single-project-section">
          <div className="container">
            <div className="card border-0">
              <div className="card-body p-4">
                <h5 className="card-title">products</h5>
                <hr />
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div className="w-75">
                    <div className="border-1 p-4 rounded">
                      <div className="">
                        <Link to="/product-add" className="hyperlink">
                          Add Product
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex flex-row justify-content-around">
                        <ProductTable />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"mt-5 d-flex justify-content-center"}>
                  <div className="mx-4">
                    <Link to={"/"} className="theme-button">
                      <span>Home</span>
                    </Link>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Product;
