import { useSelector } from "react-redux";
import store from "store";
import {
  deleteCategoryAsync,
  editCategoryAsync,
} from "store/Thunks/CategoryThunks";
import { CategoriesData, CategoriesStatus } from "store/slices/CategoriesSlice";

type params = {
  id: number;
  name?: string;
  image?: string;
  isEdit?: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

const TableRow: React.FC<params> = ({
  id,
  name,
  image,
  isEdit,
  onEdit,
  onDelete,
}): JSX.Element => {
  return (
    <tr>
      <th>
        <p>{id}</p>
      </th>
      <td>
        <img src={image} width={120} />
      </td>
      <td>
        <p>{name}</p>
      </td>
      <td className="">
        <div className="d-flex flex-column ">
          {/* <button className="hyperlink mb-2" onClick={onEdit}>
            Edit
          </button> */}
          <button className="hyperlink" onClick={onDelete}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

const CategoryTable = (): JSX.Element => {
  const categories = useSelector(CategoriesData);
  const isLoading = useSelector(CategoriesStatus);

  const editHandler = (id: number) => {
    store.dispatch(editCategoryAsync(id));
  };
  const deleteHandler = (id: number) => {
    store.dispatch(deleteCategoryAsync(id));
  };

  return (
    <div className="table-responsive table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Category Id</th>
            <th> Image </th>
            <th> Name </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading == false &&
            categories.map((item) => {
              return (
                // <p>hellow rodl!</p>
                <TableRow
                  id={item.category_id}
                  name={item.name}
                  image={item.src}
                  onDelete={() => deleteHandler(item.category_id)}
                  onEdit={() => editHandler(item.category_id)}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
