import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchProductsAsync } from "../../../store/Thunks/ProductThunks";
import store from "../../../store";
import searchIcon from "../../../assets/images/search.svg";

type params = {
  // if type equal 0 search for products 1 search for blogs
  type?: number;
  value?: string | undefined;
  queryOut?: any;
};
const SearchForm: React.FC<params> = ({
  type,
  queryOut,
  value,
}): JSX.Element => {
  const [query, setQuery] = useState("");
  const history = useHistory();

  const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setQuery(e.target.value);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== "") setQuery(value);
  }, []);

  return (
    <div className={"form-search"}>
      <img className="fas fa-search px-3" src={searchIcon} />
      <input
        className={"form-input"}
        type={"type"}
        placeholder={"search"}
        value={query}
        onChange={(e) => handleQuery(e)}
        onKeyPress={(e) => {
          value = undefined;
          if (e.key == "Enter") {
            // if (type == 1) {
            //     queryOut(query)
            //     store.dispatch(fetchPostsAsync({per_page: 10,  search: query,}))
            // }
            // else {
            history.push("/search/" + query);
            store.dispatch(fetchProductsAsync({ search: query }));
            // }
          }
        }}
      />
    </div>
  );
};

export default SearchForm;
