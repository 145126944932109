import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {intialImagesState} from "../intialState";
import {RootState} from "../index";
import { Images } from "../../models/Category";
import { addImageThunk } from "store/Thunks/ImageThunks";
import { stat } from "fs";

const ImagesSlice = createSlice({
    name: "images",
    initialState: intialImagesState,
    reducers: {
        setUploadProgress: (state, action) => {
        state.uploadProgress = action.payload;
    },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addImageThunk.pending, (state: Images) => {
                state.isLoading = true
            })
            .addCase(addImageThunk.fulfilled, (state: Images, action: PayloadAction<any>) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(addImageThunk.rejected, (state: Images, action: PayloadAction<any>) => {
                state.isLoading = false
                state.error = action.payload.message
            })

    }
})

export const {setUploadProgress} = ImagesSlice.actions

export const ImagesStatus = (state: RootState): boolean =>
    state.images.isLoading

export const ImagesData = (state: RootState): any =>
    state.images.data

export const ImageUploadProgress = (state: RootState): number => 
    state.images.uploadProgress

export const CategoriesError = (state: RootState): string =>
    state.images.error

export default ImagesSlice.reducer