import { createAsyncThunk } from "@reduxjs/toolkit"
import { ServerError } from "models/error"
import { axiosNew } from "services/api"
import { checkoutNode } from "store/slices/CheckoutSlice"


export const checkoutThunk = createAsyncThunk<
any,
any,
{
    rejectValue:ServerError
}
>(
    'checkout/addCustomer',
   async (obj, {rejectWithValue}): Promise<any> => {
        try {
            const {data}  = await axiosNew.post(`/payment/checkout`, {
                // headers:{
                //     "Authorization" : "Basic "+user.token
                // },
                data: obj
            })
            return data
        }
        catch ( error:any )
        {
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)