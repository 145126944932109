import {createAsyncThunk} from "@reduxjs/toolkit";
import {Order, OrderType} from "../../models/Order";
import { ServerError } from "models/error";
import { axiosNew } from "services/api";
import { User } from "models/user";


type paramsSearch = {
    category?: number,
    search?: string,
    per_page?: number,
    min_price?: number,
    max_price?: number,
    order_by?: string,
    include?: number[],
    product?: number,
    customers?: string,
    status?: string

    // price?: number,
}
// create Thunk fetch Product
export const fetchOrderThunk = createAsyncThunk<
any,
void,
{
    rejectValue:ServerError
}
>(
    'Order/fetchAllOrders',
    async (_,{rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.get(`/order/user/${user.user.id}`, {
                headers:{
                    "Authorization" : "Basic "+user.token
                }
            })
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

// export const fetchOrderByIdAsync = createAsyncThunk(
//     'orders/fetchOrdersById',
//     async (id:number, obj?: Partial<any>) : Promise<Order> => {
//         return SendRequestGet("orders/"+id, obj)
//     }
// )

export const fetchOrderByIdThunk = createAsyncThunk<
any,
number,
{
    rejectValue:ServerError
}
>(
    'Order/fetchOrderById',
    async (id, {rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.get(`/order/${id}`, {
                headers:{
                    "Authorization" : "Basic "+user.token
                }
            })
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)




export const postOrder = createAsyncThunk<
any,
any,
{
    rejectValue:ServerError
}
>(
    'order/postOrder',
   async (obj, {rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.post(`/order`, {
                headers:{
                    "Authorization" : "Basic "+user.token
                },
                data: obj
            })
            return data
        }
        catch ( error:any )
        {
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

export const putOrder = createAsyncThunk<
any,
any,
{
    rejectValue:ServerError
}
>(
    'order/editOrder',
   async (obj, {rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.put(`/order`, {
                headers:{
                    "Authorization" : "Basic "+user.token
                },
                data: obj
            })
            return data
        }
        catch ( error:any )
        {
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

export const deleteOrder = createAsyncThunk(
    'order/deleteOrder',
    async (id:number): Promise<any> => {
        // return SendRequestDelete("orders", {id:id})
    }
)







// ==================== get Products
// type postParams = {
//     name: string,
//     slug: string,
//     type: string,
//     description: string,
//     short_description: string,
//     regular_price: number,
//     sale_price?: number,
//     virtual: boolean
//     downloadable: boolean,
//     download:any,
//     shipping_required: boolean,
//     categories: any,
//     tags: any,
//     category: number
// }

// type paramsSearch = {
//     category?: number,
//     tag?:number,
//     search?: string,
//     per_page?: number,
//     min_price?: number,
//     max_price?: number,
//     order_by?: string
//     // price?: number,
// }

// export const fetchProductsAsync = createAsyncThunk<any, paramsSearch, {
//     rejectValue:ServerError
// }>(
//     'products/fetchRecentProducts',
//     async (params:paramsSearch, {rejectWithValue}): Promise<Array<Product>> => {
//          let obj = {
//             category: params.category || null,
//             search: params.search || null,
//             min_price: params.min_price || null,
//             max_price: params.max_price || null,
//             order: "desc",
//             per_page: params.per_page || null,
//         };
//         try{
//         const { data }  = await axiosNew.get("/products",{ params: obj})
//         return data
//     }
//     catch(error:any)
//     {
//         rejectWithValue({message: error.message, status: error.status})
//         return error
//     }
//     }
// )


// export const deleteProductsAsync = createAsyncThunk<any, number,
// {
//     rejectValue:ServerError
// }>(
//     'product/deleteProducts',
//     async (id, {rejectWithValue}): Promise<Array<Product>> => {

//         try{
//         const { data }  = await axiosNew.delete("/products/"+id)
//         return data
//     }
//     catch(error:any)
//     {
//         rejectWithValue({message: error.message, status:error.status})
//         return error.message
//     }
//     }
// )

// // create Thunk fetch Product
// export const fetchProductByIdAsync = createAsyncThunk<
//     any, number , {rejectValue:ServerError}
// >(
//     'products/fetchProductById',
//     async (productId, {rejectWithValue}) : Promise<Product> => {
//         try {
//             const {data} = await axiosNew.get(`/products/${productId}`)
//             return data
//         }
//         catch(error:any) 
//         {
//             rejectWithValue({message: error.message, status: error.status})
//             return error.message
//         }
//     }
// )




// export const PostPorudct = createAsyncThunk(
//     'product/PostPorduct',
//     async (obj: postParams): Promise<any> => {

//         return SendRequestPost("products", obj)
//     }
// )