import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReviewData, ReviewStatus } from "store/slices/ReviewSlice";
import accountIcon from "../../../assets/images/Account.svg";
import { Link } from "react-router-dom";
import { UserData } from "store/slices/UserSlice";

type params = {
  id?: number;
  user_id?: number;
  image?: string;
  username?: string;
  message?: string;
  rank?: number;
  date?: string;
  onDelete?: any;
};

const ReviewElement: React.FC<params> = ({
  id,
  user_id,
  image,
  username,
  message,
  rank,
  date,
  onDelete,
}): JSX.Element => {
  const isLoading = useSelector(ReviewStatus);
  const data = useSelector(ReviewData);
  const [index, setIndex] = useState(0);
  const user = useSelector(UserData);
  const datex = new Date();

  // useEffect(() => {
  //   console.log("the data from review is : " + JSON.stringify(data));
  //   if (isLoading == false) console.log("the data is false from review");
  // }, [isLoading]);

  return (
    <div className="my-4">
      <div className="row ">
        <div className="col-1 d-flex flex-column align-items-center">
          <img
            src={accountIcon}
            className="rounded-circle bg-light"
            width="50"
          />
          <p>
            {rank} / <b>5</b>
          </p>
        </div>
        <div className="col-10">
          <div className="border-2 p-3 border-chat bg-white">
            <div className="d-flex flex-row bg-white justify-content-between">
              <div>
                <div className="d-flex justify-content-between">
                  <b>{username}</b>
                  <p className="form-check-label">
                    {
                      date

                      //   ? date + " hello "
                      //   : datex.getFullYear() +
                      //     "-" +
                      //     datex.getMonth() +
                      //     "-" +
                      //     datex.getDay()
                    }
                  </p>
                </div>
                <p>{message}</p>
              </div>
              {user.user.id == user_id ? (
                <Link
                  to={""}
                  className="hyperlink"
                  onClick={() => onDelete(id)}
                >
                  delete
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewElement;
