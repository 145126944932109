import Header from "../../components/sections/Header";
import { useSelector } from "react-redux";
import SixProducts from "../../components/sections/SixProducts";
import WhatWeDo from "./Components/WhatWeDo";
import ProductionFeauters from "./Components/ProductionFeauters";
import ScrollHeader from "./Components/ScrollHeader";
import {
  CategoriesData,
  CategoriesStatus,
} from "../../store/slices/CategoriesSlice";
import Categories from "./Components/Categories";
import { ProductsData, ProductsStatus } from "../../store/slices/ProductsSlice";
import ContactFooter from "../../components/sections/ContactFooter";
import Footer from "components/sections/Footer";

function Home(): JSX.Element {
  // fetch products
  const recentProductIsLoading = useSelector(ProductsStatus);
  const recentProducts = useSelector(ProductsData);
  // fetch catagories
  const categories = useSelector(CategoriesData);
  const categoriesIsLoading = useSelector(CategoriesStatus);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <Header />
        <div id="content">
          <ScrollHeader
            products={recentProducts}
            isLoading={recentProductIsLoading}
          />
          <WhatWeDo />
          <ProductionFeauters />
          <SixProducts
            products={recentProducts}
            isLoading={recentProductIsLoading}
          />
          <Categories categories={categories} isLoading={categoriesIsLoading} />
          <ContactFooter />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
