import logo from "../../assets/images/logo-image.svg";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserData, UserError, UserLoading } from "../../store/slices/UserSlice";
import { fetchUser } from "../../store/Thunks/AuthThunks";

const Wrapper = (): JSX.Element => {
  const history = useHistory();
  const [uname, setUname] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setError] = useState("");
  const user = useSelector(UserData);
  const isLoading = useSelector(UserLoading);
  const error = useSelector(UserError);
  const dispatch = useDispatch();

  const handleUname = (e: any) => {
    setUname(e);
  };

  const handlePassword = (e: any) => {
    setPassword(e);
  };

  const onSubmitHandler = () => {
    dispatch(fetchUser({ username: uname, password: password }));
  };

  useEffect(() => {
    // if (error === '' && user.token !== undefined) {
    //     // console.log("the error is " + error + " and the token is :"+ user.token)
    //     localStorage.setItem("token", JSON.stringify(user))
    //     history.push("/")
    // }
    // console.log("your login is success")
    // console.log("the error is : "+ error)
    // console.log("the isLoading is : "+ isLoading)
    // if (error !== '')
    //     setError(error)
  }, []);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="content">
          <section className="contact-info-section">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {error != "" && (
                <div className={"bg-danger text-center"}>
                  <h6 className={"text-white py-3"}>{error}</h6>
                </div>
              )}
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    <div id="contact-form">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <h3> you have an Account or create a new account </h3>
                          <br />
                          <br />

                          <button onClick={() => history.push("/login")}>
                            Login
                          </button>
                          <button onClick={() => history.push("/signup")}>
                            Create a new account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
