import { useEffect, useRef, useState } from "react";
import TallProductCard from "../../../components/molecules/TallProductCard";
import Loading from "../../../assets/images/gif/product_loading.gif";
import Swiper from "swiper";
import "swiper/swiper.min.css";
import SearchForm from "../../../components/molecules/SearchForm";
import { Product } from "../../../models/product";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/all";

type params = {
  products: Array<Product>;
  isLoading: boolean;
};

const ScrollHeader: React.FC<params> = ({
  products,
  isLoading,
}): JSX.Element => {
  // const products = useSelector(ProductsRecentData)
  // const isLoading = useSelector(ProductsRecentStatus)
  const intervalref = useRef<number | null>(null);
  const [swiper, setSwiper] = useState<Swiper | undefined>(undefined);

  const startInterval = () => {
    if (intervalref.current !== null) return;
    intervalref.current = window.setInterval(() => {
      swiper?.slideNext();
    }, 2000);
  };

  const initSwiper = () => {
    const mySwiper = new Swiper(".swiper-container", {
      slidesPerView: 3,
      spaceBetween: 0,
      loop: true,
      centeredSlides: true,
      // autoplay: {
      //     delay: 500,
      //     disableOnInteraction: true,
      // },
      // navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      // },
      breakpoints: {
        // Responsive breakpoints
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
    setSwiper(mySwiper);
  };

  useEffect(() => {
    initSwiper();
    if (swiper !== undefined) {
      swiper?.slideNext();
      startInterval();
    }
    // mySwiper.slideNext();
    return () => {
      if (intervalref.current !== null) {
        window.clearInterval(intervalref.current);
      }
    };
  }, [isLoading]);

  const handleNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    swiper?.slideNext();
  };

  const handlePrev = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    swiper?.slidePrev();
  };

  return (
    <section className={"scroller-section3"}>
      <SearchForm />
      <div className={"scroller-box swiper-container"}>
        <div className={" wl-carousel swiper-wrapper"} data-num="3">
          {isLoading == true
            ? [...Array(6)].map((e, i) => {
                return (
                  <TallProductCard
                    key={"product_header_" + i}
                    id={-1}
                    name={""}
                    short_description={""}
                    images={[
                      {
                        src: Loading,
                        alt: "loading product image",
                      },
                    ]}
                  />
                );
              })
            : products !== undefined &&
              products.map((item, index) => {
                return (
                  <TallProductCard
                    key={"product_header_" + index}
                    id={item.id}
                    name={item.name}
                    short_description={item.short_description}
                    images={item.images}
                  />
                );
              })}
        </div>
        <div className={"btn-swipe-container"}>
          <div className={"btn-swipe"}>
            <AiOutlineArrowLeft />
          </div>
          <div className={"btn-swipe"}>
            <AiOutlineArrowRight />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollHeader;
