import { Link } from "react-router-dom";
import { Image } from "../../../models/product";

interface params {
  images: Image[];
  onData: any;
}

export const ImagesList: React.FC<params> = ({
  images,
  onData,
}): JSX.Element => {
  const onChangeIndex = (index: number) => {
    onData(index);
  };

  return (
    <ul className="gallery-list">
      {images.map((item, index) => (
        <li key={item.id}>
          <Link to={"#"} className="zoom" onClick={() => onChangeIndex(index)}>
            <img src={item.src} alt={"image"} />
          </Link>
        </li>
      ))}
    </ul>
  );
};
