import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContactFooter from "../../components/sections/ContactFooter";

const Contact = () => {
  const history = useHistory();

  return (
    <div id="ip-container" className="ip-container">
      <div id="container">
        <Header />
        <section className="single-post-section">
          <div className="container-xxl">
            <div className="row">
              <div className="col-lg-12 bg-white p-5">
                <ContactFooter />
              </div>
            </div>
          </div>
        </section>
        <Footer isLoading={true} />
      </div>
    </div>
  );
};

export default Contact;
