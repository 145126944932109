import { createAsyncThunk } from "@reduxjs/toolkit"
import { ServerError } from "models/error"
import { axiosNew } from "services/api"

import { setUploadProgress } from "store/slices/ImageSlice"
import { setUploadProgressArchive } from "store/slices/ArchiveSlice"


export const addImageThunk = createAsyncThunk<any,any,
{
    rejectValue:ServerError
}
>(
    'Image/CreateImage',
    async (params ,{dispatch, rejectWithValue}): Promise<any> => {
        try {
            // const storage = await localStorage.getItem("token")
            // if (storage == undefined)
            //     return rejectWithValue({message: "Please Login", status: 403})
            // let user:User = JSON.parse(storage) as User
            // console.log("the data from cart Thunk is: "+ JSON.stringify(user))
            const {data}  = await axiosNew.post(`/image/uploadx`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                 onUploadProgress: (progressEvent: any) => {
                    const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total);
                    // dispatch(setError("okok"))
                    console.log("the progress is : "+ progress)
                    dispatch(setUploadProgress(progress))
                    
            },
            });
            console.log("the result from image is: "+ JSON.stringify(data));
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)

