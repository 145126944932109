import WooCommerceRestApi from "woocommerce-rest-ts-api";
import axios from "axios";

export const BaseUrl:string = "https://back.bysabprint.com/"

export const wooApi = new WooCommerceRestApi({
    url: BaseUrl,
    consumerKey: 'ck_566b30ed8b9a6fb0b7d9b93e9c48e0736610d06f',
    consumerSecret: 'cs_4825bdbd9ee61de86c0335e299975356e557fc6c',
    version: 'wc/v3',
});


export const axiosApi = axios.create({
    baseURL: 'https://back.bysabprint.com/wp-json/',
    // headers: {
    //     consumer_key: 'ck_566b30ed8b9a6fb0b7d9b93e9c48e0736610d06fy',
    //     consumer_secret: 'cs_4825bdbd9ee61de86c0335e299975356e557fc6c',
    // },
});


export const axiosNew = axios.create ({
    baseURL: 'https://dev.bysabprint.com',
});


