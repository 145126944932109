import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReviewData, ReviewStatus } from "store/slices/ReviewSlice";
import star from "../../../assets/icons/Star 1.svg";
import starempty from "../../../assets/icons/Star-empty.svg";
import { TextArea, TextField } from "components/atoms/TextField";
import ReviewElement from "./ReviewElement";
import { UserData } from "store/slices/UserSlice";
import store from "store";
import { addReviewThunk, removeReviewThunk } from "store/Thunks/ReviewThunks";

type params = {
  productId?: number;
  description?: string;
};

const Review: React.FC<params> = ({ productId, description }): JSX.Element => {
  const isLoading = useSelector(ReviewStatus);
  const data = useSelector(ReviewData);
  const [index, setIndex] = useState<number | undefined>(undefined);
  const [message, setMessage] = useState("");
  const user = useSelector(UserData);
  // useEffect(() => {
  //   if (isLoading == false) console.log("the data is false from review");
  // }, [isLoading]);

  const handleClick = (e: number) => {
    if (e != undefined) setIndex(e);
  };

  const handleMessage = (e: any) => {
    setMessage(e);
  };
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(
      addReviewThunk({
        user_id: user.user.id,
        product_id: productId,
        username: user.user.username,
        message: message,
        rank: index != undefined ? index + 1 : 0,
      })
    );
    // while (isLogin != true);
    // while (state.isLogin == true);
  };

  const OnDeleteHandler = (id?: number) => {
    if (id != undefined)
      store.dispatch(
        removeReviewThunk({
          user_id: user.user.id,
          review_id: id,
        })
      );
  };

  return (
    <section className="portfolio-section">
      <div className="container-xl">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-bs-toggle="tab" href="#home">
              Description
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#menu1">
              Review ({data.length})
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div id="home" className="container tab-pane active">
            <br />
            <p>{description}</p>
          </div>
          <div id="menu1" className="container tab-pane fade">
            <br />
            <h3>Overall rating</h3>
            <div className="py-3">
              {[...Array(5)].map((e, i) => (
                <button className="button-icon" onClick={() => handleClick(i)}>
                  <img
                    src={index != undefined && i <= index ? star : starempty}
                  />
                </button>
              ))}
            </div>
            {/* <div className="contact-form-box border-0"> */}
            <form id="simple-form" onSubmit={onSubmitHandler}>
              <div className="col-md-6 border-0 py-3 mb-5">
                <TextArea
                  id={"message"}
                  name={"message"}
                  placeholder={"Message"}
                  type={"text"}
                  onChange={handleMessage}
                />
                <button
                  type="submit"
                  id="submit_contact"
                  className={"button-one"}
                >
                  <span>Submit Review</span>
                </button>
              </div>
            </form>
            {isLoading == false ? (
              data.map((e) => (
                <ReviewElement
                  message={e.message}
                  username={e.username}
                  rank={e.rank}
                  date={e.data_created}
                  user_id={e.user_id}
                  id={e.review_id}
                  onDelete={OnDeleteHandler}
                />
              ))
            ) : (
              <p>is Loading....</p>
            )}
          </div>
          {/* <h3>Menu 1</h3> */}
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Review;
