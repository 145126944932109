import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CategoriesData,
  CategoriesStatus,
} from "../../../store/slices/CategoriesSlice";
import { number } from "zod";

type params = {
  catid: any;
  type?: number;
};
const CategoriesDropDownList: React.FC<params> = ({
  catid,
  type,
}): JSX.Element => {
  const categories = useSelector(CategoriesData);
  const isLoading = useSelector(CategoriesStatus);
  // const [value, setValue] = useState("")
  // const [id, setId] = useState()

  const handleId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    // setValue(selectedValue);
    catid(selectedValue);
  };

  useEffect(() => {
    // console.log("the value of categories: "+ JSON.stringify(value))
  }, []);

  return (
    <div className="col-12">
      <div className={"input-line"}>
        {/*<div className={"input-line"}>*/}
        {type != 2 && (
          <label htmlFor="inputProductType" className="form-label">
            Categories
          </label>
        )}
        {/*<a className={"hyperlink"}>Add New Category</a>*/}
        {/*</div>*/}
        <select
          className="inputcheck"
          id="inputProductType"
          onChange={handleId}
        >
          <option selected disabled hidden>
            Select Category
          </option>
          {isLoading == false ? (
            categories.map((item) => (
              <option value={item.category_id} key={item.category_id}>
                {item.name}
              </option>
            ))
          ) : (
            <option>""</option>
          )}
        </select>
      </div>
    </div>
  );
};

export default CategoriesDropDownList;
