import { configureStore } from '@reduxjs/toolkit'
import productIdReducer from "./slices/productIdSlice"
import userReducer from "./slices/UserSlice"
import ordersReducer from "./slices/OrderSlice"
import orderReducer from "./slices/OrderIdSlice"
import categoriesReducer from "./slices/CategoriesSlice"
import productsReducer from "./slices/ProductsSlice"
import cartReducer from "./slices/CartSlice"
import imageReducer from "./slices/ImageSlice"
import archiveReducer from "./slices/ArchiveSlice"
import reviewReducer from "./slices/ReviewSlice"
import checkoutReducer from "./slices/CheckoutSlice"
import downloadReducer from "./slices/DowloadsSlice"

const store = configureStore({
  reducer: {
    user: userReducer,
    products: productsReducer,
    productById: productIdReducer,
    orders: ordersReducer,
    order: orderReducer,
    images: imageReducer,
    archive: archiveReducer,
    categories:  categoriesReducer,
    cart: cartReducer,
    review: reviewReducer,
    checkout: checkoutReducer,
    downloads: downloadReducer,
  },
})

// export type appDispash = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
